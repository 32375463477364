import React from "react";
import { useState, useEffect, useMemo } from "react";
import { Route, Navigate, useNavigate } from 'react-router-dom';

import axios, {isCancel, AxiosError, AxiosResponse} from 'axios';
import Login from "../components/Login";
import { appConfiguration } from '../appConfig';

let config = appConfiguration.apiConfig;


axios.defaults.withCredentials = true

export const responseBody = (response: AxiosResponse) => response;

const corsOptions = {
        
    methods: ['POST', 'GET', 'PATCH', 'DELETE', 'OPTIONS'],
    allowedHeaders: ['Content-type','Authorization','Origin','Access-Control-Allow-Origin','Accept','Options','X-Requested-With']
};

export class figuration {
    SupplierPortalApiUrl = ""
}

export class ApiRequest {

    static getRequest = async (requestPath = '') => {
        let appConfig = await config;
        console.log('get request -', config);
        let baseUrl = appConfig.SupplierPortalApiUrl;
        let requestUrl = baseUrl + requestPath;
        axios.get(
            requestUrl
            ).then(response => {
                console.log('ok', response);
                return response;
            } 
            ).catch(err => { 
                console.log('err', err);
                return err; 
            })
    }


    static postRequest = async (url = '', postData: any, options: any) => {
        let res;
        let appConfig = await config;
        console.log('post request -', await config);

        let baseUrl = appConfig.SupplierPortalApiUrl;
        let requestUrl = baseUrl + url;
        console.log('api-makeRequest', 'url',requestUrl, 'data', postData, 'options', options);
        if (url) {
            res = axios.post(
                    requestUrl,
                    postData, { 
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true, //set in defaults above
                }

            ).then(response => { 
                console.log(response);
                if (typeof response.data !== 'undefined'){
                    return response.data; 
                } else {
                        return response;
                }
                
            }).catch(err => { 
                console.log(err); 
                if (typeof err?.response?.status !== 'undefined' && err.response.status === 401 ){
                    console.log("401 Status returned");
                    window.location.href= "/login";
                    
                     return err.response;
                } else {
                    return err.response;
                }

            })
            
        }

        return res;
    }

    // static makeRequest = async (url = '', postData: any, options: any) => {
    //     let res;
    //     let requestUrl = baseUrl + url;
    //     console.log('api-makeRequest', 'url',requestUrl, 'data', postData, 'options', options);
    //     if (url) {
    //         res = axios(
    //             {
    //                 method: options.method,
    //                 url: requestUrl,
    //                 data: postData,
                    
    //                 headers: {
    //                     //'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Origin, Access-Control-Allow-Credentials, Access-Control-Allow-Methods',
    //                     'Content-Type': 'application/json',
    //                     //'Access-Control-Allow-Origin': 'https://localhost:5001',  //this is a server response
    //                     //'X-PINGOTHER': 'pingpong',
    //                     //"Access-Control-Allow-Credentials": true  //this is a server response
    //                     //"Access-Control-Allow-Methods": "DELETE, POST, GET, PUT, OPTIONS"
    //                 },
    //                 withCredentials: true, //set in defaults above
    //             },
    //         ).then((responseBody) => {
    //             console.log('response',responseBody);
    //             return responseBody;
    //         }).catch((err: AxiosError) => {
    //             console.log('error',err);
    //             console.error('err message', err.response?.status, err.response?.statusText, err.message);
    //             return err;
    //         });
    //     }

    //     return res;
    // }
}
