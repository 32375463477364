import React, { MouseEvent } from "react";
import { useState, useEffect, useMemo } from "react";
import { Route, Navigate, useNavigate } from 'react-router-dom';
import TableFilter from "./TableContainer";
import { AxiosError, AxiosResponse } from 'axios';
import { ApiRequest } from "../api/ApiAxios"
import Login from "./Login";
import { DateRangeColumnFilter, dateBetweenFilterFn, SelectColumnFilter, MultipleFilter } from "./Filter";
import PoModalComponent from './Modal/PoModal';
import RfqModalComponent from './Modal/RfqModal';
import Tabs from "./Tabs";

const All: React.FC = () => {
    //  FETCH DATA STARTS
    const [meOnly, setMeOnly] = useState<boolean>((window.localStorage.getItem('OnlyMe')  !== null ? window.localStorage.getItem('OnlyMe') === "true" : false));
    const [actionValue, setActionValue] = useState<boolean>(window.localStorage.getItem('actionValue')  !== null ? window.localStorage.getItem('actionValue') === "true" : false);
    const [data, setData] = useState([]);
    const [response, setResponse] = useState([]);
    const [alldata, setAllData] = useState([]);
    const [actionItems, setActionItems]= useState([]);
    const [counter, setCounter]= useState({allCount: "", rfqCount: "", poCount: ""});
    const [count, setCount]= useState(0);
    const [rowNumber, setRowNumber]= useState<number>();
    const [selectedRows, setSelectedRows]= useState<any>([]);
    
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            //'Access-Control-Allow-Origin': '*',
            //'BfsAuthToken': 'postman-12341234',
            //'BfsAuthTokenSecret': 'postman-secret-12341234',
        },
    };

    //no parameters need to be set as a default
    let postData = {
        'meOnly' : (window.localStorage.getItem('OnlyMe')  !== null ? window.localStorage.getItem('OnlyMe') === "true" : false)
    }

    const navigate = useNavigate(); //redirect to login    
    useEffect(() => {
        try {
            console.log('userEffect');
            let requestUrl = 'WorkList/Search';
            (async () => {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };
                const response = await ApiRequest.postRequest(requestUrl, postData, requestOptions);
                console.log('showall response', response);
                console.log("POST DATA",postData);

                let data = (response as AxiosResponse)?.data;
                setResponse(data);
                
                if (typeof data?.rows?.$values !== 'undefined') {
                    const rowData = data.rows.$values;
                    const actionData = data.rows.$values.filter(({ rfqStatus, poStatus }: any) => rfqStatus == "COUNTERED" || rfqStatus == "NEW" || poStatus == "PO CREATED");
                    console.log('values', rowData);
                    if(actionValue){
                        setData(actionData);
                    }
                    else{
                        setData(rowData);
                    }
                    setAllData(rowData);
                    setActionItems(actionData);
                    setCount(count+1);
                }

            })();


        } catch (error) {
            let err = error as AxiosError
            console.log()
            console.log('error', err);
        }
    }, [meOnly]);

    //  FETCH DATA ENDS

    // ONLY SHOW ACTION ITEMS STARTS
    const handleShowItems = (value:any) => {
        console.log('show all - handle change only me', value.target.checked, value);
        window.localStorage.setItem('actionValue', value.target.checked);
        setActionValue(value.target.checked);
        if(value.target.checked){
            setData(actionItems);
        }
        else{
            setData(alldata);
        }
        setCount(count+1);
    }
    // ONLY SHOW ACTION ITEMS ENDS

    //  PO MODAL STARTS

    const [open, setOpen] = useState<any>(false);
    const [poId, setPoId] = useState<MouseEvent<HTMLAnchorElement, MouseEvent>[]>();

    const handleChange = (value: number) => {
        console.log('show all - handle change', value);
        let po: any = value;
        console.log(po);
        setPoId(po);
        setOpen(true);
    }
    const handleOnChangeOnlyMe = (value:any) => {
        console.log('show all - handle change only me', value.target.checked, value);
        window.localStorage.setItem('OnlyMe', value.target.checked);
        setMeOnly(value.target.checked);
    }

    const setPoChange = (dataFromPo : any) => {
        const newData: any = [...data];
        console.log(dataFromPo, rowNumber);
        if(rowNumber != null){
            newData[rowNumber]['poStatus'] = dataFromPo.toUpperCase();
            setData(newData);
            setCount(count+1);
        }
    }

    //  PO MODAL ENDS

    // GETTING ROW INDEX
    
    const getrowIndex = (rowIndex : any) => {
        console.log(rowIndex);
        setRowNumber(rowIndex);
    }

    //  RFQ MODAL STARTS

    const [rfqId, setRfqId] = useState<any>({rfqid: "", rfqmasterid: ""});;
    const [rfqData, setRfqData] = useState<any[]>([]);

    const [open1, setOpen1] = useState<any>(false);
    const handleRfq = (rfq1: number, rfq2: number) => {
        let rfqM: any = rfq1;
        let rfqC: any = rfq2;
        console.log(rfqM, rfqC);
        setRfqId({rfqid: rfqC, rfqmasterid: rfqM});
        setTimeout(function(){
            setOpen1(true);
        },1000)
    }

    const setRfqChange = (dataFromRfq : any) => {
        const newData: any = [...data];
        console.log('showAll, setRfqChange',dataFromRfq, rowNumber);
        if(rowNumber != null){
            newData[rowNumber]['rfqStatus'] = dataFromRfq.toUpperCase();
            setData(newData);
            setCount(count+1);
        }
    }
    
    //  RFQ MODAL ENDS

    //  Getting the Count Starts
    
    const handleRowsSelected = (item: any) => {
        const defaultallCount = item.filter((row: any) => row.original.rfqStatus == "COUNTERED" || row.original.rfqStatus == "NEW" || row.original.poStatus == "PO CREATED").length;
        const defaultrfqCount = item.filter((row: any) => row.original.rfqStatus == "COUNTERED" || row.original.rfqStatus == "NEW").length;
        const defaultpoCount = item.filter((row: any) => row.original.poStatus == "PO CREATED").length;
        setCounter({allCount: defaultallCount, rfqCount: defaultrfqCount, poCount: defaultpoCount});
        console.log("Show All Counts", defaultallCount,defaultrfqCount,defaultpoCount);
    }

    //  Getting the Count Ends

    const columns = useMemo(
        () => [
            {
                Header: "RFQ",
                accessor: "rfqMasterId",
                Cell: ({ row }: { row: any}) => (
                    row.original ? <a onClick={() => handleRfq(row.original.rfqMasterId, row.original.rfqId)}>{row.original.rfqMasterId}</a> : null
                )
                // Cell: ({ value }: { value: any }) => (
                //     value ? <a onClick={() => handleRfq(value)}>{value}</a> : null
                // )
            },
            {
                Header: "Buyer",
                accessor: "rfqBuyerName",
                Filter: SelectColumnFilter,
                filter: MultipleFilter,
            },
            {
                Header: "Location",
                accessor: "rfqLocationName",
            },
            {
                Header: "Date Sent",
                accessor: "rfqDateSent",
                Filter: DateRangeColumnFilter,
                filter: dateBetweenFilterFn,
            },
            {
                Header: "Product",
                accessor: "rfqProductDisplay",
            },
            {
                Header: "RFQ Status",
                accessor: "rfqStatus",
                Filter: SelectColumnFilter,
                filter: MultipleFilter,
                Cell: ({ value }: { value: string }) => (
                    value ? <button className={value}>{value}</button> : null
                )
            },
            {
                Header: "PO",
                accessor: "erpPoId",
                Cell: ({ row }: { row: any}) => (
                    row.original ? <a onClick={() => handleChange(row.original.purchaseOrderId)}>{row.original.erpPoId}</a> : null
                )
            },
            {
                Header: "Last Change",
                accessor: "poLastDateChange",
                Filter: DateRangeColumnFilter,
                filter: dateBetweenFilterFn,
            },
            {
                Header: "PO Status",
                accessor: "poStatus",
                Filter: SelectColumnFilter,
                filter: MultipleFilter,
                Cell: ({ value }: { value: string }) => (
                    value ? <button className={value}>{value}</button> : null
                )
            },
        ],
        []
    );

    return (
        <>
        <Tabs onChangeOnlyMe={handleOnChangeOnlyMe} showActionValues={handleShowItems} counter={counter}/>
        <div className="table-wrapper">
            <div className="table">
                <TableFilter columns={columns} data={data} count={count} getIndex ={getrowIndex} getRows={handleRowsSelected} response={response}/>
            </div>

            {/* MODAL COMPONETS */}

            <RfqModalComponent open1={open1} setOpen1={setOpen1} rfqId={rfqId} setRfqStatus={setRfqChange}/>
            <PoModalComponent open={open} setOpen={setOpen} poId={poId} setPoStatus={setPoChange}/>

            {/* MODAL COMPONETS */}
        </div>
        </>
    );
}

export default All;