import React from 'react'
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import TopLayout  from "./components/TopLayout";
import ShowAll from "./components/ShowAll";
import Rfq from "./components/Rfq";
import Po from "./components/Po";
import Login from "./components/Login";
import Register from './components/Register';
import Account from './components/EditAccount';
import AccountVerification from './components/AccountVerification';
import ChangePassword from './components/ChangePassword';

function App() {
  return (
    <div className="App">
        <Router>
          <Routes>
              <Route path="/login" element={<Login />}/>
              <Route path="/register" element={<Register />}/>
              <Route path="/accountverification" element={<AccountVerification />}/>
              <Route path="/changepassword" element={<ChangePassword />}/>
              <Route element={<TopLayout />}> 
                <Route path="/" element={<ShowAll />}/>
                <Route path="/rfq" element={<Rfq />}/>
                <Route path="/po" element={<Po />}/>
                <Route path="/account" element={<Account />} />
              </Route>
          </Routes>

        </Router>

    </div>
  );
}

export default App;
