export const buildConfiguration = () => new Promise(
    (resolve) => {
        if (window.appConfig) {
            resolve(window.appConfig);
        } else {
            fetch('./appConfig.json', { cache: 'no-store' }).then((response) => {
                response.json().then((json) => {
                    console.log('appconfig.js', json);
                    window.appConfig = json;
                    resolve(window.appConfig);
                });
            });
        }
    }, (reject) => {
        console.error('appConfig could not be found!!!');
        reject('appConfig.json could not be loaded');
    },
);

export const appConfiguration = {
 
    get apiConfig() {
        const getConfig = (async function () {
            return await buildConfiguration();
        });
        let config = getConfig().then(value => value);
        return config;
    },
};

