import { useState, useEffect, useMemo, useRef } from "react";
import { Dropdown, Table, Modal, Input, Button } from 'semantic-ui-react';
import { Row, Col } from 'reactstrap';
import { AxiosError, AxiosResponse } from 'axios';
import { ApiRequest } from "../../api/ApiAxios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';

type Props = {
    open: boolean;
    setOpen: any;
    poId: any;
    setPoStatus: any;
};
const PoModalComponent: React.FC<Props> = ({
    open,
    setOpen,
    poId,
    setPoStatus
}) => {


    //  PO MODAL STARTS

    const [disableUpdateButtons, setDisableUpdateButtons] = useState<boolean>(false);

    const [loadId, setLoadId] = useState<number>(Math.random());
    const [toggle, setToggle] = useState<boolean>(false);
    const [poData, setPoData] = useState<any[]>([]);
    const [textState, setTextState] = useState("Show More Details");
    const [shipDate, setShipDate] = useState<string>();
    const [deliveryDate, setDeliveryDate] = useState<string>();
    //const [account, setAccount] = useState<any>();
    const [notesToBuyerState, setNotesToBuyerState] = useState<any>();
    const [vendorMillState, setVendorMillState] = useState<any>();
    const [vendorNotes, setVendorNotes] = useState<any>([]);

    const toggleText = () => {
        setTextState((state) => (state === "Show Less Details" ? "Show More Details" : "Show Less Details"));
        setToggle(!toggle)
    };

    const handleCloseModal = () => {
        setLoadId(Math.random());
        setOpen(!open);
        setVendorNotes([]);
    }

    const options1 = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
    };
    let postData1 = {
        "PurchaseOrderId": poId
    }

    useEffect(() => {

        let requestUrl1 = 'purchaseorder/getpodetails';
        console.log(poId);
        (async () => {
            const requestOptions1 = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            setDisableUpdateButtons(true);

            if (poId != null) {
                const response = await ApiRequest.postRequest(requestUrl1, postData1, requestOptions1);
                const poDatas = response.rows.$values;
                let vendorNotes = poDatas[0].notesToVendor as string;
                if (vendorNotes !== '') {
                    setTextState("Show Less Details");
                    setToggle(true)
                } else {
                    setTextState("Show More Details");
                    setToggle(false)
                }
                // Vendor Notes Starts
                const VendorNotesArray =
                    [
                        poDatas[0].tarp ? "Tarped" : '',
                        poDatas[0].paperWrapped ? "Paper Wrapped" : '',
                        poDatas[0].fscCertified ? "FSC Certified" : '',
                        poDatas[0].convertToLFSku ? "Convert to LFSKU" : '',
                    ];
                setVendorNotes(VendorNotesArray);

                // Vendor Notes Ends

                const shipDate = new Date(poDatas[0].shipDate).toLocaleString('en-GB').slice(0, 10);
                var [days, months, years] = shipDate.split("/");
                var shipDateFormatted = [months, days, years].join("/");
                const deliveryDate = new Date(poDatas[0].deliveryDate).toLocaleString('en-GB').slice(0, 10);
                var [days1, months1, years1] = deliveryDate.split("/");
                var deliveryDateFormatted = [months1, days1, years1].join("/");
                setShipDate(shipDateFormatted);
                setDeliveryDate(deliveryDateFormatted);
                console.log(poDatas);
                setPoData(poDatas);
                //setAccount(response.accountId);
                setNotesToBuyerState(poDatas[0].notesToBuyer ?? "");
                setVendorMillState(poDatas[0].vendorMill ?? "");

                //used to control render of inputs
                setLoadId(Math.random());

                let poStatus = poDatas[0].status as string;
                if (poStatus.startsWith("CONFIRM")) {
                    setDisableUpdateButtons(true);
                } else {
                    setDisableUpdateButtons(false);
                }

                //if (poData[0].status !== null && poData[0].status.toString().start)
            }
        })();
    }, [poId]);

    //  PO MODAL ENDS

    // Submit PO
    const handleInputChange = (event: any) => {
        console.log('handleHeaderInputChange', event);
        console.log(event.target.name, event.target.value);


        switch (event.target.name) {
            case 'NotesToBuyer':
                setNotesToBuyerState(event.target.value);
                break;
            case 'VendorMill':
                setVendorMillState(event.target.value);
                break;
            default:
                break;
        }
        console.log('po handleinputchange', notesToBuyerState,)

    }

    const handleSubmit = (e: any, status: string) => {
        console.log('handleSubmit', status, e, e.target.name);

        let poUpdateUrl = 'PurchaseOrder/UpdateStatus';
        let payload = {
            "PurchaseOrderId": poData[0].purchaseOrderId,
            "PurchaseOrderStatus": status,
            "Mill": vendorMillState,
            "NotesToBuyer": notesToBuyerState
            //cns: this is handled via the cookies "requestAccountId", ignore requestAccountId 
        };
        console.log(payload);
        (async () => {
            const requestOptions1 = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const response = await ApiRequest.postRequest(poUpdateUrl, payload, requestOptions1);
            // const poDatas = response.rows.$values;
            console.log(response);
            //used to control render of inputs
            setLoadId(Math.random());

            if (response.data.purchaseOrder.status == "REJECTED") {
                toast.error(response.data.purchaseOrder.status, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
            else {
                toast.success(response.data.purchaseOrder.status, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
            console.log(response.data.purchaseOrder.status);
            if ((response.data.purchaseOrder.status as string) === "CONFIRMED") {
                setDisableUpdateButtons(true);
            }

            // Changing The PO Status

            setPoStatus(response.data.purchaseOrder.status);

            // Changing The PO Status

            setTimeout(function () {
                handleCloseModal();
            }, 3000);
        })();
    }

    // Submit PO

    // Export Pdf      
    const printRef = useRef<any>(null);

    const exportPdf = () => {
        try {

            setTimeout(async () => {
                const canvas = await html2canvas(printRef.current);
                const imageData = canvas.toDataURL('image/png');

                const pdf = new jsPDF('landscape', 'px', 'a4');
                const imgProperties = pdf.getImageProperties(imageData);
                const pdfWidth = pdf.internal.pageSize.getWidth() - 6;
                const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width - 6;

                pdf.addImage(imageData, 'PNG', 3, 3, pdfWidth, pdfHeight);
                pdf.save(`${poData[0].locationCode}_${poData[0].erpPurchaseOrderId}_PO.pdf`);

            }, 200);
        } catch (error) {
            console.error('Error Converting and Saving as PDF', error);
        }

    };
    // Export Pdf

    return (
        <div className="Modal-wrapper">
            <ToastContainer />
            <Modal
                open={open}
                onClose={handleCloseModal}
                className="po-modal"
            >
                <Modal.Header>
                    <Row className="justify-content-between">
                        <Col>
                            PURCHASE ORDER
                        </Col>
                        <Col className="text-right">
                            <span className="remove-icon" onClick={handleCloseModal}>
                                <img src="images/remove.svg" alt="Close" />
                            </span>
                        </Col>
                    </Row>

                </Modal.Header>
                <Modal.Content scrolling>

                    <Modal.Description>
                        {poData.map((po: any, i: any) => {
                            { console.log(poData, i) }
                            return (
                                <div ref={printRef} className="main-wrapper">
                                    <div className="detail-wrapper">
                                        <div className="head-wrapper d-flex align-items-center">
                                            <div className="logo-wrapper">
                                                <img src="images/logo.png" alt="Logo" />
                                            </div>
                                            <span className="button-wrap">BFS PO #: <strong>{poData[i].erpPurchaseOrderId}</strong></span>
                                            <span className="button-wrap">Buyer: <strong>{poData[i].buyerDisplayName}</strong></span>
                                            <span className="button-wrap">Location: <strong>{poData[i].locationCode}</strong></span>
                                            <span
                                                className={`showmore-link ${toggle && 'showing'}`}
                                                onClick={toggleText}>
                                                {textState}
                                                <img src="images/chevron.svg" alt="" />
                                            </span>
                                        </div>
                                        <div className={`showmore-wrapper mb-3 ${toggle && 'showing'}`}>
                                            <Row className="mb-3">

                                                <Col sm="auto" className="text-right ml-auto">
                                                    <Dropdown
                                                        text='EXPORT'
                                                        button
                                                        className='dropdown-button'
                                                    >
                                                        <Dropdown.Menu>
                                                            {/* <Dropdown.Item
                                                                text='RFQ as PDF'
                                                            />
                                                            <Dropdown.Item
                                                                text='RFQ as Excel'
                                                            >
                                                            </Dropdown.Item> */}
                                                            <Dropdown.Item
                                                                text='PO as PDF'
                                                                onClick={exportPdf}
                                                            />
                                                            {/* <Dropdown.Item
                                                                text='PO as Excel'
                                                            /> */}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </Col>
                                            </Row>
                                            <div className="button-wrap px-sm-4 mb-3 me-0">
                                                <Row>
                                                    <Col sm="2">
                                                        <strong>Delivery Information</strong>
                                                        <address>
                                                            <span>{poData[i].location.locationCode}</span>
                                                            <span>{poData[i].location.name}</span>
                                                            <span>{poData[i].location.addressLine1}</span>
                                                            <span>{poData[i].location.addressLine2}</span>
                                                            <span>{poData[i].location.city}</span>
                                                            <span>{poData[i].location.stateProvince}</span>
                                                            <span>{poData[i].location.postalCode}</span>
                                                            <span>{poData[i].location.locationPhone}</span>
                                                        </address>
                                                    </Col>
                                                    <Col sm="2">
                                                        <strong>Vendor Information</strong>
                                                        <address>
                                                            <span>{poData[i].vendorContactName}</span>
                                                            <span>{poData[i].vendorName}</span>
                                                            <span>{poData[i].vendorAddress1}</span>
                                                            <span>{poData[i].vendorAddress2}</span>
                                                            <span>{poData[i].vendorState}</span>
                                                            <span>{poData[i].vendorPostalCode}</span>
                                                            <span>{poData[i].vendorPhone}</span>
                                                        </address>
                                                    </Col>
                                                    <Col sm="2">
                                                        <strong>Properties</strong>
                                                        <ul className="m-0 properties">
                                                            {vendorNotes.map((item: any) => {
                                                                return (
                                                                    item ? <li>{item}</li> : ''
                                                                )
                                                            })}
                                                        </ul>
                                                    </Col>
                                                    <Col sm="6">
                                                        <div className="notes-wrapper">
                                                        {poData[i].notesToVendor || poData[i].pricedByNotes || poData[i].vendorEmailNotes ? (<><strong className="notes">Notes To Vendor</strong>
                                                            <div className="buyer-comments">
                                                                <ul>
                                                                    {poData[i].pricedByNotes ? <li>{poData[i].pricedByNotes}</li> : ''}
                                                                    {poData[i].notesToVendor ? <li>{poData[i].notesToVendor}</li> : ''}
                                                                    {poData[i].vendorEmailNotes ? <li>{poData[i].vendorEmailNotes}</li> : ''}
                                                                </ul>
                                                            </div></>
                                                        ): ''}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className="button-wrap px-sm-4 me-0">
                                                <div className="ship-wrapper mb-2">
                                                    <strong>Shipment Details</strong>
                                                    <Row>
                                                        {/* <Col sm="auto">Loads: <strong></strong></Col> */}
                                                        <Col sm="auto">Ship Date: <strong>{shipDate}</strong></Col>
                                                        <Col sm="auto">Delivery Date: <strong>{deliveryDate}</strong></Col>
                                                        <Col sm="auto">Ship Method: <strong>{poData[i].shipMethod}</strong></Col>
                                                    </Row>
                                                </div>
                                                <div className="price-wrapper">
                                                    <strong>Pricing Details</strong>
                                                    <Row>
                                                        <Col sm="auto">PTS: <strong>{poData[i].pts ? "Yes" : "No"}</strong></Col>
                                                        <Col sm="auto">Contract: <strong>{poData[i].contract ? "Yes" : "No"}</strong></Col>
                                                        <Col sm="auto">Terms: <strong>{poData[i].terms}</strong></Col>
                                                        <Col sm="auto">FOB: <strong>{poData[i].fob}</strong></Col>
                                                        <Col sm="auto">Freight: <strong>{poData[i].freight}</strong></Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="load-wrapper mb-3">
                                        <div className="table-wrapper">
                                            <div className="table">
                                                <Table celled striped>
                                                    <Table.Header className="main-head">
                                                        <Table.Row>
                                                            <Table.HeaderCell width={8}>SKU</Table.HeaderCell>
                                                            <Table.HeaderCell width={8}>Product</Table.HeaderCell>
                                                            <Table.HeaderCell>Length</Table.HeaderCell>
                                                            <Table.HeaderCell>Units</Table.HeaderCell>
                                                            <Table.HeaderCell>Qty/Unit</Table.HeaderCell>
                                                            <Table.HeaderCell width={4}>Ext Qty</Table.HeaderCell>
                                                            <Table.HeaderCell width={4} className="text-right">Price</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        {poData[i].purchaseOrderLineItems.$values.map((list: any) => {
                                                            return (
                                                                <Table.Row>
                                                                    <Table.Cell>{list.sku}</Table.Cell>
                                                                    <Table.Cell>{list.productDescription}</Table.Cell>
                                                                    <Table.Cell>{list.length}</Table.Cell>
                                                                    <Table.Cell>{list.units ? new Intl.NumberFormat().format(list.units): ''}</Table.Cell>
                                                                    <Table.Cell>{list.quantityOfUnits ? new Intl.NumberFormat().format(list.quantityOfUnits): ''}</Table.Cell>
                                                                    <Table.Cell>{list.extendedQuantity ? new Intl.NumberFormat().format(list.extendedQuantity): ''}</Table.Cell>
                                                                    <Table.Cell className="text-right">{"$" + list.price.toLocaleString(undefined, { minimumFractionDigits: 4 })}</Table.Cell>
                                                                </Table.Row>
                                                            )
                                                        })}

                                                    </Table.Body>
                                                </Table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="button-wrap m-0">
                                        <Row>
                                            <Col sm="4">
                                                <label htmlFor="">Mill:</label>
                                                <Input key={"vendorMaill-" + loadId} name="VendorMill" disabled={disableUpdateButtons} defaultValue={vendorMillState} onChange={(event) => handleInputChange(event)} width="100%" />
                                            </Col>
                                            <Col sm="4">
                                                <label htmlFor="">Notes:</label>
                                                <Input key={"notesToBuyer-" + loadId} name="NotesToBuyer" disabled={disableUpdateButtons} defaultValue={notesToBuyerState} onChange={(event) => handleInputChange(event)} width="100%" />
                                            </Col>
                                            <Col sm="4">
                                                <label htmlFor="">Total:</label>
                                                <Input key={"totalPrice-" + loadId} name="TotalPrice" className="text-align-right total" readOnly value={"$" + poData[i].totalPrice.toLocaleString(undefined, { minimumFractionDigits: 2 })} width="100%" />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            )
                        })}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button name="cancel-btn" className="cancel-btn" onClick={handleCloseModal}>
                        CANCEL
                    </Button>
                    <Button name="reject-btn" className="reject-btn"
                        disabled={disableUpdateButtons}
                        onClick={(event) => handleSubmit(event, "Rejected")}>
                        REJECT
                    </Button>
                    <Button name="confirm-btn"
                        className="confirm-btn"
                        disabled={disableUpdateButtons}
                        onClick={(event) => handleSubmit(event, "Confirmed")}>
                        CONFIRM
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
}

export default PoModalComponent;