import React from "react";
import { useState, useEffect } from "react";
import { AxiosError, AxiosResponse } from 'axios';
import { ApiRequest } from "../api/ApiAxios"
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ShowHidePassword from "./Inputs/ShowHidePassword";

const Account: React.FC = () => {

    let navigate: NavigateFunction = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [userMessage, setUserMessage] = useState<string>("");
    const [accountdetails, SetAccountDetails] = useState<any>({});

    const initialValues: {
        FirstName: string,
        LastName: string,
        CompanyDescription: string,
        Phone: string,
        NotifyRfq: boolean,
        NotifyRFQCounter: boolean,
        NotifyPO: boolean
    } = {
        FirstName: accountdetails.firstName,
        LastName: accountdetails.lastName,
        CompanyDescription: accountdetails.companyDescription,
        Phone: accountdetails.phone,
        NotifyRfq: accountdetails.notifyRFQ,
        NotifyRFQCounter: accountdetails.notifyRFQCounter,
        NotifyPO: accountdetails.notifyPO
    };

   
    useEffect(() => {
        let requestUrl = 'security/getuserprofile';
        const data = {};
        (async () => {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const response = await ApiRequest.postRequest(requestUrl, data, requestOptions);

            if (response === null) {
                setUserMessage("Can not perform action.  Please try again or call support.")
            } else if (response.status === 401) {
                console.log("account edit unsuccessful", response.status, response);
                setUserMessage("Account session is no longer valid.  Please login.");
                navigate("/");
            }
            SetAccountDetails(response.appUser);
        })();
    }, []);

    const handleCancel = (event:any) => {
        navigate("/");
    }

    const validationSchema = Yup.object().shape({
        FirstName: Yup.string().required("This field is required!"),
        LastName: Yup.string().required("This field is required!"),
        CompanyDescription: Yup.string().required("This field is required!"),
        Phone: Yup.string().required("This field is required!")
    });

    const handleAccountEdit = (formValue: { FirstName: string; LastName: string; CompanyDescription: string; Phone: any; NotifyRfq: boolean;
        NotifyRFQCounter: boolean;
        NotifyPO: boolean;}) => {
        const { FirstName, LastName, CompanyDescription, Phone, NotifyRfq, NotifyRFQCounter, NotifyPO } = formValue;
        console.log('handleAccountEdit', formValue)

        try {

            let requestUrl = 'security/UpdateUserProfile';
            console.log('security/UpdateUserProfile',formValue);
            (async () => {
                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                };
                const response = await ApiRequest.postRequest(requestUrl, formValue, options);
                console.log('account edit response', response);

                if (response.status === 400) {
                    console.log("account edit unsuccessful", response.status, response.data?.errors, response);
                    setUserMessage("Invalid request");
                } else if (response.status === 401) {
                    console.log("account edit unsuccessful", response.status, response);
                    setUserMessage("Account session is no longer valid.  Please login.");
                    //navigate("/");
                } else {
                    setUserMessage("Account has been updated. Click cancel to return to main page.");
                    // save displayname in local storage
                    localStorage.setItem('UserDisplayName', response.appUser.displayName);
                    localStorage.getItem('UserDisplayName');
                    console.log('login - about to navigate to root1', response.appUser.displayName);
                    navigate("/");
                }


            })();


        } catch (error) {
            let err = error as AxiosError
            console.log('error', err);
        }


    };

    return (
        <div id="register-page" className="login-page">
            <div className="form-bg-wrapper">
                <div className="formbox-wrapper d-flex justify-content-center">
                    <div className="inner-form">
                        <div className="d-flex justify-content-between align-items-baseline flex-wrap">
                            <h1>VENDOR PORTAL</h1>
                            <div className="img-wrapper text-center">
                                <img src="images/form-logo.svg" alt="" />
                            </div>
                        </div>
                        <h2 className="text-center">EDIT ACCOUNT</h2>
                        <Formik
                            enableReinitialize 
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleAccountEdit}
                        >
                            <Form>
                                <Row>
                                    <Col sm="6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="firstname">First Name</label>
                                            <Field name="FirstName" type="text" />
                                            <ErrorMessage name="FirstName" className="error-message" component="label" />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="company">Company</label>
                                            <Field name="CompanyDescription" type="text" />
                                            <ErrorMessage name="CompanyDescription" className="error-message" component="label" />
                                        </div>
                                        {/* <div className="form-group mb-3">
                                            <label htmlFor="Email">Email Address</label>
                                            <Field name="Email" type="email" />
                                            <ErrorMessage name="Email" className="error-message" component="label" />
                                        </div> */}
                                        {/* <div className="form-group mb-1">
                                            <label htmlFor="Password">Password</label>
                                            <ShowHidePassword name="Password"/>
                                        </div>
                                        <div className="form-group mb-2">
                                            <label htmlFor="ConfirmPassword">Confirm Password</label>
                                            <ShowHidePassword name="ConfirmPassword"/>
                                        </div>
                                        <div className="form-group text-right mb-3">
                                            <a href="javascript:" className="text-right">Forgot Password?</a>
                                        </div> */}
                                    </Col>
                                    <Col sm="6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="lastname">Last Name</label>
                                            <Field name="LastName" type="text" />
                                            <ErrorMessage name="LastName" className="error-message" component="label" />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="phone">Phone</label>
                                            <Field name="Phone" type="text" />
                                            <ErrorMessage name="Phone" className="error-message" component="label" />
                                        </div>
                                        <div className="checkbox-wrapper">
                                            <label htmlFor="rfq-email" className="custom-checkbox d-flex align-items-center">
                                                <Field id="rfq-email" type="checkbox" name="NotifyRfq"/>
                                                <span className="indicator"></span>
                                                <span className="label-text">New RFQ Email Notifications</span>
                                            </label>
                                            <label htmlFor="rfq-counter" className="custom-checkbox d-flex align-items-center">
                                                <Field id="rfq-counter" type="checkbox" name="NotifyRFQCounter"/>
                                                <span className="indicator"></span>
                                                <span className="label-text">RFQ Counter Email Notifications</span>
                                            </label>
                                            <label htmlFor="po-email" className="custom-checkbox d-flex align-items-center">
                                                <Field id="po-email" type="checkbox" name="NotifyPO" />
                                                <span className="indicator"></span>
                                                <span className="label-text">PO Email Notifications</span>
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="button-wrapper d-flex mt-4 justify-content-end align-items-center">
                                    <button type="button" className="cancel-btn" onClick={(event) => handleCancel(event)}>
                                        CANCEL
                                    </button>
                                    <button type="submit" disabled={loading} className="login-btn">
                                        SAVE ACCOUNT
                                    </button>
                                </div>
                            </Form>
                        </Formik>
                        <div>
                                    <span className="error-message">{userMessage}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="empty-blocker"></div>
            <footer className="login-footer">
                <div className="log-wrapper">
                    <img src="images/login-logo.svg" alt="" />
                </div>
                <a href="javascript:">TERMS AND CONDITIONS</a>
                <span className="d-block">© 2018 Builders FirstSource, Inc. All rights reserved.</span>
            </footer>
        </div>
    );
}

export default Account;