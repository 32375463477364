import React, { BaseSyntheticEvent, Fragment } from "react";
import { useState, useEffect, useRef, useMemo } from "react";
import { Dropdown, Table, Modal, Input, Button, Select, TextArea, Tab, Popup } from 'semantic-ui-react';
import { Row, Col } from 'reactstrap';
import { AxiosError, AxiosResponse } from 'axios';
import { ApiRequest } from "../../api/ApiAxios";
import { listenerCount } from "process";
import { isNaN } from "formik";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
//import { useForm } from 'react-hook-form';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';

type Props = {
    open1: boolean;
    setOpen1: any;
    rfqId: any;
    setRfqStatus: any;
};

//convert camel case to pascal
// type CapitalizeObjectKeys<T> = {
//     [key in keyof T as Capitalize<key & string>]: T[key] extends Object ? CapitalizeObjectKeys<T[key]> : T[key]
// };

type Nullable<T> = T | null;
interface ResponseCounterDetail {
    $id: number;
    RfqRowId: string;
    RfqLineItemId: number;
    Sku: string;
    ProductDescription: string;
    ProductLength: string;
    Units: number;
    QtyOfUnits: number;
    NoQuote : boolean;
    Price: any;
    ProductNotes: string;
};
const responseCounterDetailDefaultValue = {
    $id: -1,
    RfqRowId: "",
    RfqLineItemId: -1,
    Sku: "",
    ProductDescription: "",
    ProductLength: "",
    Units: 0,
    QtyOfUnits: 0,
    NoQuote: false,
    Price: null,
    ProductNotes: ""
};
interface ResponseCounter {
    RfqId: number;
    PTSLoad: boolean;
    Freight?: string;
    FOB: string;
    NoQuoteAll: boolean;
    ShipMethod: string;
    ShipDate?: any;
    DeliveryDate?: any;
    Mill: string;
    NumberOfLoads?: any;
    NotesToBuyer: string;
    ResponseToCounter: boolean;
    Archived: boolean;
    Rejected: boolean;
    Accepted: boolean;
    CounterDetails: ResponseCounterDetail[];
};
const responseCounterDefaultValue = {
    RfqId: -1,
    PTSLoad: false,
    Freight: "",
    FOB: "FOBMILL",
    NoQuoteAll: false,
    ShipMethod: "TRUCK",
    ShipDate: new Date(),
    DeliveryDate: new Date(),
    Mill: "",
    NotesToBuyer: "",
    NumberOfLoads: "",
    ResponseToCounter: false,
    Archived: false,
    Rejected: false,
    Accepted: false,
    CounterDetails: []
};


const RfqModalComponent: React.FC<Props> = ({
    open1,
    setOpen1,
    rfqId,
    setRfqStatus
}) => {
    const [disableUpdateButtons, setDisableUpdateButtons] = useState<boolean>(responseCounterDefaultValue.Archived);
    const [loadId, setLoadId] = useState<number>(Math.random());
    const [responseCounterState, setResponseCounterState] = useState<ResponseCounter>(responseCounterDefaultValue);
    const [rfqData, setRfqData] = useState<any[]>([]);
    const [selectOptions, setSelectOptions] = useState([]);
    const [shipDate, setShipDate] = useState<string>();
    const [deliveryDate, setDeliveryDate] = useState<string>();
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [vendorNotes, setVendorNotes] = useState<any>([]);
    const inputRefs = useRef<any>([]);
    
    const shipDateInput = useRef<any>();
    const deliveryDates = useRef<any>();

    const millRef = useRef<any>();
    const [stateRandomValue, setStateRandomValue] = useState<number>();
    const [millValue, setMillValue] = useState<string>('');
    const [notesToBuyerValue, setNotesToBuyerValue] = useState<string>('');


    //pre- comes from RFQ, maybe rfqShipDateValue
    //const [preshipValue, setPreShipValue] = useState<any>();
    //const [predeliveryValue, setPreDeliveryValue] = useState<any>();
    const [requote, setRequote] = useState<boolean>(false);
    const [showTags, setShowTags] = useState<boolean>(false);
    const [quote, setQuote] = useState();
    const [buyerdates, SetBuyerDates] = useState<any>({ shipBuyerDate: '', deliveryBuyerDate: '' });
    //const [lineItems, setlineItems] = useState<any[]>([]);

    const handleCloseModal = () => {
        console.log('handling close modal');

        setOpen1(!open1);
        setSelectedRows([]);
        setDisabled(false);
        setResponseCounterState(responseCounterDefaultValue);
        setVendorNotes([]);
    }

    const options1 = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
    };
    let postData1 = {
        "sessionId": "1",
        "rfqId": rfqId.rfqid
    }
    useEffect(() => {

        if (!open1){
            return;
        }

        setResponseCounterState(responseCounterDefaultValue);
        setDisableUpdateButtons(responseCounterDefaultValue.Archived);

        let requestUrl1 = 'rfq/search';
        console.log("VALUES",rfqId.rfqid, rfqId.rfqmasterid);
        (async () => {
            const requestOptions1 = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            if (rfqId.rfqid != null) {
                const response = await ApiRequest.postRequest(requestUrl1, postData1, requestOptions1);
                const rfqData = response.rows.$values;
                const shipOptions = response.shipViaOptions.$values;
                // Buyer Comments Starts
                const VendorNotesArray =                     
                [
                    rfqData[0].tarp ? "Tarped": '',
                    rfqData[0].paperWrapped ? "Paper Wrapped": '',
                    rfqData[0].fscCertified ? "FSC Certified" : '',
                    rfqData[0].designateMill ? "Mill designated": '',
                    rfqData[0].designateSpecies ? "Species designated": ''
                    // rfqData[0].tarp ? "Flatbed loads must be tarped": '',
                    // rfqData[0].paperWrapped ? "All products must be paper wrapped": '',
                    // rfqData[0].fscCertified ? "All products must be FSC certified" : '',
                    // rfqData[0].designateMill ? "Mill must be designated": '',
                    // rfqData[0].designateSpecies ? "Species must be designated": ''
                ];
                setVendorNotes(VendorNotesArray);

                // Buyer Comments Ends

                const options = shipOptions.map((item: { name: any; }): any => ({
                    key: item.name,
                    value: item.name,
                    text: item.name
                }));

                if (rfqData[0].shipDate !== null) {
                    const preshipDate = new Date(rfqData[0].shipDate).toLocaleString('en-GB').slice(0, 10);
                    var [days, months, years] = preshipDate.split("/");
                    var shipDateFormatted = [years, months, days].join("-");
                    setShipDate(shipDateFormatted);
                } else {
                    setShipDate('');
                }

                if (rfqData[0].shipDate !== null) {
                    const predeliveryDate = new Date(rfqData[0].deliveryDate).toLocaleString('en-GB').slice(0, 10);
                    var [days1, months1, years1] = predeliveryDate.split("/");
                    var deliveryDateFormatted = [years1, months1, days1].join("-");
                    setDeliveryDate(deliveryDateFormatted);
                } else {
                    setDeliveryDate('');
                }

                let rfqArchived = rfqData[0]?.archived ?? responseCounterDefaultValue.Archived;
                if (rfqArchived === true) {
                    setDisableUpdateButtons(true);
                } else {
                    setDisableUpdateButtons(false);
                }
                console.log('rfq archived',rfqData[0]?.archived,rfqArchived);

                setRfqData(rfqData);
                setSelectOptions(options);

                console.log('RrfqData', rfqData);

                if (rfqData[0].lastBuyerCounter !== null) {
                    const preshipDate = new Date(rfqData[0].lastBuyerCounter.shipDate).toLocaleString('en-GB').slice(0, 10);
                    var [days, months, years] = preshipDate.split("/");
                    var shipDateFormatted = [months, days, years].join("/");
                    setShipDate(shipDateFormatted);

                    const predeliveryDate = new Date(rfqData[0].lastBuyerCounter.deliveryDate).toLocaleString('en-GB').slice(0, 10);
                    var [days1, months1, years1] = predeliveryDate.split("/");
                    var deliveryDateFormatted = [months1, days1, years1].join("/");
                    setDeliveryDate(deliveryDateFormatted);

                    SetBuyerDates({ shipBuyerDate: shipDateFormatted, deliveryBuyerDate: deliveryDateFormatted })
                    setRequote(true);
                    setShowTags(true);
                }
                else {
                    setRequote(false);
                    setShowTags(false);
                }

                //create response object to initialize and send back with POST                
                let responseCounterObj = responseCounterDefaultValue;
                responseCounterObj.Mill = '';

                responseCounterObj.RfqId = rfqId.rfqid;

                if (!Array.isArray(rfqData[0].lastVendorCounter?.counterDetails?.$values)) {


                    responseCounterObj.ShipMethod = rfqData[0].shipMethod;
                    responseCounterObj.Freight = rfqData[0].freight;
                    responseCounterObj.FOB = rfqData[0].fob;
                    responseCounterObj.NoQuoteAll = false;
                    responseCounterObj.ShipDate = rfqData[0].shipDate;
                    responseCounterObj.DeliveryDate = rfqData[0].deliveryDate;
                    responseCounterObj.Mill = '';  
                    responseCounterObj.NumberOfLoads = rfqData[0].numberOfLoads;
                    responseCounterObj.NotesToBuyer = '';
                    responseCounterObj.PTSLoad = rfqData[0].isPTSLoad ?? false;
                    
                    // Sorting RfqLine Items
                    let sortedData = rfqData[0].rfqLineItems.$values.sort((a: any, b: any) => (a.rfqLineItemId > b.rfqLineItemId) ? 1 : -1);

                    let details = sortedData.map((row: any, index: any) => {
                        let dtl = {
                            $id: row.$id,
                            RfqRowId: row.$id,
                            RfqLineItemId: row.rfqLineItemId,

                            Sku: row.sku,

                            Units: row.units,
                            QtyOfUnits: row.quantityOfUnits,
                            NoQuote: row.noQuote || false,
                            Price: null,
                            // Price: 0.00,
                            ProductNotes: '',

                            //RFQ Display Fields
                            ProductDescription: row.productDescription,
                            ProductLength: row.productLength
                        };

                        return dtl;

                    });
                    responseCounterObj.CounterDetails = details || [];
                    console.log('responsecounterobj', responseCounterObj);
                } else {

                    let cntr = rfqData[0].lastVendorCounter;
                    console.log('cntr - init', cntr);

                    let cntrDetails = rfqData[0].lastVendorCounter?.counterDetails?.$values;

                    console.log('init state with last vendor counter', cntrDetails);

                    responseCounterObj.ShipMethod = cntr.shipMethod ?? rfqData[0].shipMethod;
                    responseCounterObj.Freight = cntr.freight ?? rfqData[0].freight;
                    responseCounterObj.FOB = cntr.fob ?? rfqData[0].fob;
                    responseCounterObj.NoQuoteAll = cntr.noQuoteAll ?? false;
                    responseCounterObj.ShipDate = cntr.shipDate ?? rfqData[0].shipDate;
                    responseCounterObj.DeliveryDate = cntr.deliveryDate ?? rfqData[0].deliveryDate;
                    responseCounterObj.Mill = cntr.mill ?? '';
                    responseCounterObj.NumberOfLoads = cntr.numberOfLoads ?? '';
                    responseCounterObj.NotesToBuyer = cntr.notesToBuyer ?? '';
                    responseCounterObj.PTSLoad = cntr.ptsLoad ?? rfqData[0].isPTSLoad ?? false;

                    // Sorting RfqLine Items
                    let sortedData = rfqData[0].rfqLineItems.$values.sort((a: any, b: any) => (a.rfqLineItemId > b.rfqLineItemId) ? 1 : -1);

                    let details = sortedData.map((row: any, index: any) => {
                        let cntrDetail = cntrDetails.filter((f: any) => f.sku === row.sku)[0];
                        
                        let dtl : ResponseCounterDetail = {
                            $id: row.$id,
                            RfqRowId: row.$id,
                            RfqLineItemId: row.rfqLineItemId,

                            Sku: row.sku,

                            Units: cntrDetail?.units ?? row.units,
                            QtyOfUnits: cntrDetail?.qtyOfUnits ?? row.quantityOfUnits,
                            NoQuote: cntrDetail.noQuote || false,
                            Price: cntrDetail?.price ?? null,
                            // Price: cntrDetail?.price ?? 0.00,
                            ProductNotes: cntrDetail?.productNotes ?? '',

                            //RFQ Display Fields
                            ProductDescription: row.productDescription,
                            ProductLength: row.productLength
                        };

                        return dtl;

                    });

                    //initializing calendars to last counter instead of original rfq
                    //const shipDate2 = new Date(responseCounterObj.ShipDate).toISOString().slice(0, 10);
                    //const deliveryDate2 = new Date(responseCounterObj.DeliveryDate).toISOString().slice(0, 10);
                    if (responseCounterObj.ShipDate !== null) {
                        const preshipDate2 = new Date(responseCounterObj.ShipDate).toLocaleString('en-GB').slice(0, 10);
                        var [days, months, years] = preshipDate2.split("/");
                        var shipDateFormatted2 = [years, months, days].join("-");
                        setShipDate(shipDateFormatted2);
                    } else {
                        setShipDate('');
                    }
                    
                    if (responseCounterObj.DeliveryDate !== null) {
                        const predeliveryDate2 = new Date(responseCounterObj.DeliveryDate).toLocaleString('en-GB').slice(0, 10);
                        var [days1, months1, years1] = predeliveryDate2.split("/");
                        var deliveryDateFormatted2 = [years1, months1, days1].join("-");
                        setDeliveryDate(deliveryDateFormatted2);
                    } else {
                        setDeliveryDate('');
                    }
                    
                    responseCounterObj.CounterDetails = details || [];
                    console.log('responsecounterobj-from last buyer', responseCounterObj, details);

                }
                console.log('responsecounterstate', responseCounterState);

                setResponseCounterState(responseCounterObj);

                //check if there has been a change in required fields
                validateForm();

                setStateRandomValue(Math.random());

                //reset LoadId - used to know when to re-render, else will always re-render when state changes
                setLoadId(Math.random());
            }
        })();
        // updateRfq();
    }, [open1]);

    const handleSelectAll = (e: any) => {
        const allRowIds: any = rfqData[0].rfqLineItems.$values.map((row: { $id: number; }) => row.$id);
        var rc = responseCounterState;
        if (e.target.checked){
            rc.NoQuoteAll = true;
        } else {
            rc.NoQuoteAll = false;
        }
        rc.CounterDetails = rc.CounterDetails.map((d) => { 
            var rtn = d; 
            rtn.NoQuote = rc.NoQuoteAll; 
            if (rtn.NoQuote == true){
                rtn.Price = '';
            } 
            return rtn;
        });
        setResponseCounterState(rc);
        console.log('handleSelectAll', responseCounterState);

        validateForm();

        //re-render input fields and reset focus
        setStateRandomValue(Math.random());
        setLoadId(Math.random());
    }

    const handleKeyDown = (event: any, currentIndex: any) => {
        console.log('handleKeyDown', event, currentIndex);
        if (event.key === 'Tab') {
            event.preventDefault();
            const nextIndex = currentIndex + 1;
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
            else {
                shipDateInput.current.focus();
            }
        }
    };

    const handleShipDateKeyDown = (event:any) => {
        if (event.key === 'Tab') {
            console.log('handleDeliveryDateKeyDown', event.key, event);
            event.preventDefault();
            deliveryDates.current.focus();
        }
    }

    const handleDeliveryDateKeyDown = (event:any) => {
        if (event.key === 'Tab') {
            console.log('handleDeliveryDateKeyDown', event.key, event);
            event.preventDefault();
            millRef.current.focus();
        }
    }

    const handleRef = (ref: any, index: any) => {
        console.log('handleRef', index, ref);
        inputRefs.current[index] = ref;
    }

    const handleHeaderShipMethodChange = (value: any) => {
        console.log('handleShipMethodChange', value, value.target.innerText);
        var rc = responseCounterState;
        if (value?.target?.innerText != null && value.target.innerText.length > 3) {
            rc.ShipMethod = value.target.innerText;
        }
        setStateRandomValue(Math.random());

    }

    const handleHeaderInputChange = (event: any) => {
        console.log('handleHeaderInputChange', event);
        console.log(event.target.name, event.target.value);

        var rc = responseCounterState;

        switch (event.target.name) {
            case 'PTSLoad':
                rc.PTSLoad = event.target.checked;
                break;
            case 'FOB':
                rc.FOB = event.target.value;
                break;
            case 'Freight':
                rc.Freight = event.target.value;
                break;
            case 'ShipDate':
                rc.ShipDate = event.target.value;
                setShipDate(event.target.value);
                break;
            case 'DeliveryDate':
                rc.DeliveryDate = event.target.value;
                setDeliveryDate(event.target.value);
                break;
            case 'Mill':
                rc.Mill = event.target.value;
                //setMillValue(rc.Mill);
                break;
            case 'NumberOfLoads':
                rc.NumberOfLoads = event.target.value;
                break;
            case 'NotesToBuyer':
                rc.NotesToBuyer = event.target.value;
                //setMillValue(rc.Mill);
                break;
            default:
                break;
        }

        console.log('handleheaderinputchange',responseCounterState);
  
        setResponseCounterState(rc);

        validateForm();

        setStateRandomValue(Math.random());
    }

    const handleInputChange = (value: any, index: any) => {
        console.log('handleInputChange', value);
        var event = value?.event || value;

        console.log(event.target.name, event.target?.value || event.target.checked);

        var rc = responseCounterState;
        var line = (rc.CounterDetails[index] as ResponseCounterDetail);

        switch (event.target.name) {
            case 'Units':
                line.Units = event.target.value;
                break;
            case 'QtyOfUnits':
                line.QtyOfUnits = event.target.value;
                break;
            case 'NoQuote':
                console.log('updating now quote', event.target.checked);
                line.NoQuote = event.target.checked;
                if (line.NoQuote){
                    line.Price = '';
                    //if all others are checked then set NoQuote to true
                    if (rc.CounterDetails.every((m) => m.NoQuote || m.RfqLineItemId == line.RfqLineItemId)) {
                        rc.NoQuoteAll = true;
                    }
                } else {
                    //no quote all is false if 1 No Quote is false
                    rc.NoQuoteAll = false;
                }
                break;
            case 'Price':
                var value = event.target.value;
                var p = (value.replace(/[^0-9.]/g, '')?.toString().trim() ?? "");
                line.Price = p;
                setQuote(p);
                if (line.Price !== ''){
                    line.NoQuote = false;
                    rc.NoQuoteAll = false;
                }

                break;
            case 'ProductNotes':
                line.ProductNotes = event.target.value;
                break;

            default:
                break;
        }
        console.log('rc', rc, 'line', line);
        setResponseCounterState(rc);
        rc.CounterDetails[index] = line;

        //check if there has been a change in required fields
        validateForm();

        setStateRandomValue(Math.random());

    };

    const [disabled, setDisabled] = useState<boolean>(true);

    const validateForm = () => {
        var rc = responseCounterState;
        console.log('in validation form',responseCounterState, rc.CounterDetails.some((s) => s.Price == ''));

        var errors = "";

        if (rc.NoQuoteAll == false && rc.CounterDetails.some((s) => (s.Price === null || s.Price === '') && s.NoQuote == false)) {
            console.log('disabling submit button');
            setDisabled(true);
        } else if (rc.ShipDate === null || rc.ShipDate === '') {
            console.log('formvalidation - invalid ship date', shipDate);
            setDisabled(true);
        } else if (rc.DeliveryDate === null || rc.DeliveryDate === '') {
            console.log('formvalidation - invalid delivery date', deliveryDate);
            setDisabled(true);
        } else {
            console.log('enabling submit button');
            setDisabled(false);
        }

    }

    // Note: Submit Handler
    const handleSubmit = (e: any) => {
        //e.preventDefault();
        setDisabled(false);
        console.log('handlesubmit', e.target.name, e.target.name);
        console.log('handleSubmit', responseCounterState);
        console.log('selectedQuotes', selectedRows);
        console.log('dates', shipDate, deliveryDate);

        //update response with selected rows
        var rc = responseCounterState;

        if (rfqData[0].rfqStatus != null && rfqData[0].rfqStatus === 'COUNTERED'){
            rc.ResponseToCounter = true;
        } else {
            rc.ResponseToCounter = false;
        }


        if (e.target.name === 'accept-btn') {
            rc.Accepted = true;
            rc.Rejected = false;

            console.log('submit lastbuyercounter', rfqData[0].lastBuyerCounter);

            //re-init fields with last buyer values
            if (rfqData[0].lastBuyerCounter !== null) {
                var bc = rfqData[0].lastBuyerCounter;
                rc.ShipMethod = bc.shipMethod;
                rc.FOB = bc.fob;
                rc.Freight = bc.freight;
                rc.ShipDate = bc.shipDate;
                rc.DeliveryDate = bc.deliveryDate;
                rc.Mill = bc.mill;
                rc.NumberOfLoads = bc.numberOfLoads;
                rc.NotesToBuyer = bc.notesToBuyer;
                rc.CounterDetails = bc.counterDetails.$values.map((m: any, index: number) => {
                    let rtn = rc.CounterDetails.find((d) => d.RfqLineItemId === m.rfqLineItemId) as ResponseCounterDetail;
                    rtn.QtyOfUnits = m.qtyOfUnits;
                    rtn.NoQuote = m.noQuote || false;
                    rtn.Price = m.price;
                    rtn.Units = m.units;

                    return rtn;
                });

            }
            console.log('accept all transform', rc);

        } else if (e.target.name === 'reject-btn') {
            rc.Rejected = true;
            rc.Accepted = false;
        } else {
            rc.Rejected = false;
            rc.Accepted = false;
        }


        setResponseCounterState(rc);
        console.log('submitted response counter state', responseCounterState);

        (async () => {
            const requestOptions1 = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            var postUrl = 'rfq/UpdateRFQResponse';
            var data = rc; //responseCounterState;
            if (data.Freight == '') {
                delete data.Freight;
            }
            if (data.NumberOfLoads == '') {
                delete data.NumberOfLoads;
            }
            //if (data.ShipDate == null){
            console.log('submit data', data.ShipDate, data.DeliveryDate);
            if (data.ShipDate === null) {
                console.log('removing ship date');
                delete data.ShipDate;
            }
            //if (data.DeliveryDate instanceof Date && !isNaN(data.DeliveryDate.getTime())) {
            if (data.DeliveryDate === null) {
                console.log('removing delivery date');
                delete data.DeliveryDate;
            }
            console.log('submit data', data);

             data.CounterDetails = data.CounterDetails.map((d: any) => {

                //remove properties that can't be case into numeric (int and decimal) types.
                if (d.Price == '') {
                    delete d.Price;
                }
                if (d.Units == '') {
                    delete d.Units;
                }
                if (d.QtyOfUnits == '') {
                    delete d.QtyOfUnits;
                }
                return d;
            });

            try {
                const response = await ApiRequest.postRequest(postUrl, data, requestOptions1);
                console.log('rfq modal submit response', response);
                if (response.rfq.rfqStatus !== null){
                    console.log('RfqModal,submit,setting rfqstatus callback', response, response?.rfq, response?.rfq?.rfqStatus);
                    setRfqStatus(response.rfq.rfqStatus);
                }
                handleCloseModal();
            } catch (error) {
                handleCloseModal();                
            }

        })();


    }

    // Export Pdf      
    const printRef = useRef<any>(null);

    const exportPdf = () => {
        try{
            
            setTimeout(async () => {
                const canvas = await html2canvas(printRef.current, {
                    onclone: (document) => {
                        const clonedElement: any = document.querySelector('.load-inner');
                        const clonedButton: any = document.querySelector('.dropdown-button');
                        clonedElement.style.boxShadow = 'none';
                        clonedButton.style.boxShadow = 'none';
                    },
                });
                const imageData = canvas.toDataURL('image/png');
        
                const pdf = new jsPDF('landscape', 'px', 'a4');
                const imgProperties = pdf.getImageProperties(imageData);
                const pdfWidth = pdf.internal.pageSize.getWidth() - 6;
                const pdfHeight =(imgProperties.height * pdfWidth) / imgProperties.width - 6;
        
                pdf.addImage(imageData, 'PNG', 3 , 3, pdfWidth, pdfHeight);
                pdf.save(`${rfqData[0].locationCode}_${rfqData[0].rfqMasterId}_RFQ.pdf`);
    
            },200);
        }catch (error) {
            console.error('Error Converting and Saving as PDF', error);
        }

    };
    // Export Pdf

    return (
        <div className="Modal-wrapper">
            <form action="">
                <Modal
                    className="Rfq-Modal"
                    open={open1}
                    onClose={handleCloseModal}
                >
                    <Modal.Header>
                        <Row className="justify-content-between">
                            <Col>
                                QUOTE
                            </Col>
                            <Col className="text-right">
                                <span className="remove-icon" onClick={handleCloseModal}>
                                    <img src="images/remove.svg" alt="Close" />
                                </span>
                            </Col>
                        </Row>

                    </Modal.Header>
                    <Modal.Content scrolling>
                        <Modal.Description>
                            {rfqData.map((po: any, i: any) => {
                                // { console.log(rfqData, i) }
                                let lastBuyerCounter = rfqData[0].lastBuyerCounter;
                                let lastVendorCounter = rfqData[0].lastVendorCounter;
                                console.log('lastBuyerCounter', lastBuyerCounter, 'lastVendorCounter', lastVendorCounter);
                                return (
                                    <div className="main-wrapper">
                                        <div ref={printRef} className="detail-wrapper">
                                            <div className="showmore-wrapper showing">
                                                <div className="head-wrapper d-flex align-items-center">
                                                    <div className="logo-wrapper">
                                                        <img src="images/modal-logo.png" alt="Logo" />
                                                    </div>
                                                    <span className="button-wrap">RFQ: <strong>{rfqData[i].rfqMasterId}</strong></span>
                                                    <span className="button-wrap">Buyer: <strong>{rfqData[i].buyerName}</strong></span>
                                                    <span className="button-wrap">Location: <strong>{rfqData[i].locationCode}</strong></span>
                                                    {/* <span
                                                        className="showmore-link">
                                                        EMAIL BUYER
                                                    </span> */}
                                                    <Dropdown
                                                        text='EXPORT'
                                                        button
                                                        className='ml-auto dropdown-button'
                                                    >
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item
                                                                text='RFQ as PDF'
                                                                onClick={exportPdf}
                                                            />
                                                            {/* <Dropdown.Item
                                                                text='RFQ as Excel'
                                                            >
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                text='PO as PDF'
                                                            />
                                                            <Dropdown.Item
                                                                text='PO as Excel'
                                                            /> */}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            <div className="load-wrapper mb-3">
                                                <div className="load-inner">
                                                    <div className="load-title">
                                                        <Row>
                                                            <Col sm="6">
                                                                <h3>Load {rfqData[0].loadNumber ? rfqData[0].loadNumber : 1 }</h3>
                                                            </Col>
                                                            <Col sm="6">
                                                                <div className="checkbox-wrapper d-flex justify-content-end">
                                                                    <label htmlFor="no" className="custom-checkbox d-flex align-items-center">
                                                                        <input  key={"noQuoteAll-"+loadId} name="NoQuoteAll" type="checkbox" onChange={handleSelectAll} checked={responseCounterState.NoQuoteAll || responseCounterDefaultValue.NoQuoteAll} />
                                                                        <span className="indicator"></span>
                                                                        <span className="label-text">No Quote Load</span>
                                                                    </label>
                                                                </div>
                                                                {showTags && (lastBuyerCounter.noQuoteAll !== lastVendorCounter.noQuoteAll) && <span className="buyercounter-value d-flex justify-content-end">Buyer countering to: {rfqData[0].lastBuyerCounter.noQuoteAll?.toString() == true ? "Checked" : "Unchecked"}</span>}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className="form-wrapper">
                                                        <Row className="input-wrapper mb-4">
                                                            <Col sm="3">
                                                                <label htmlFor="shipping-method">Shipping Method</label>
                                                                <Select
                                                                    key={"shippingMethod-"+loadId}
                                                                    id="shipping-method"
                                                                    placeholder='Select Your Shipping Method'
                                                                    value={responseCounterState.ShipMethod}
                                                                    options={selectOptions}
                                                                    onChange={(event) => handleHeaderShipMethodChange(event)}
                                                                />
                                                                {showTags && (lastBuyerCounter.shipMethod !== lastVendorCounter.shipMethod) && <span className="buyercounter-value">Buyer countering to: {rfqData[0].lastBuyerCounter.shipMethod}</span>}
                                                            </Col>
                                                            <Col sm="3">
                                                                <label htmlFor="" className="mb-2">Freight Information</label>
                                                                <div className="radio-wrapper d-flex">
                                                                    <div className="radio me-3">
                                                                        <input key={"fob-destination-"+loadId} id="destination" name="FOB" type="radio" value="FOBDEST" checked={(responseCounterState.FOB ?? '') === 'FOBDEST'} onChange={(event) => handleHeaderInputChange(event)} />
                                                                        <label htmlFor="destination" className="radio-label">FOB Destination</label>
                                                                    </div>
                                                                    <div className="radio">
                                                                        <input key={"fob-origin-"+loadId} id="origin" name="FOB" type="radio" value="FOBMILL" checked={(responseCounterState.FOB ?? '') === 'FOBMILL'} onChange={(event) => handleHeaderInputChange(event)} />
                                                                        <label htmlFor="origin" className="radio-label">FOB Origin</label>
                                                                    </div>
                                                                </div>
                                                                {showTags && (lastBuyerCounter.fob !== lastVendorCounter.fob) && 
                                                                <span className="buyercounter-value">Buyer countering to: {rfqData[0].lastBuyerCounter.fob}</span>}
                                                            </Col>
                                                            <Col sm="3">
                                                                <label htmlFor="">Freight</label>
                                                                <input type="number" key={"freight-"+loadId} name="Freight" value={(responseCounterState.Freight || responseCounterDefaultValue.Freight)} onChange={(event) => handleHeaderInputChange(event)} />
                                                                {showTags && ((lastBuyerCounter.freight ?? 0) !== (lastVendorCounter.freight ?? 0)) && 
                                                                <span className="buyercounter-value">Buyer countering to: {rfqData[0].lastBuyerCounter.freight}</span>}
                                                            </Col>
                                                            <Col sm="3">
                                                                <Row>
                                                                    <Col sm="5">
                                                                        <div className="checkbox-wrapper d-flex justify-content-between">
                                                                            <label htmlFor="yes" className="custom-checkbox d-flex align-items-center mr-2">
                                                                                <input
                                                                                    key={"ptsLoad-"+loadId}
                                                                                    type="checkbox"
                                                                                    name="PTSLoad"
                                                                                    defaultChecked={responseCounterState.PTSLoad}
                                                                                    onChange={(event) => handleHeaderInputChange(event)} />
                                                                                <span className="indicator"></span>
                                                                                <span className="label-text">PTS Load</span>
                                                                            </label>
                                                                        </div>
                                                                        {showTags &&  ((lastBuyerCounter.ptsLoad ?? "") !== (lastVendorCounter.ptsLoad ?? "")) && 
                                                                        <span className="buyercounter-value">Buyer countering to: {rfqData[0].lastBuyerCounter.ptsLoad.toString() == true ? "Checked" : "Unchecked"}</span>}
                                                                    </Col>
                                                                    <Col sm="7" className="payment-options">
                                                                        <label htmlFor="payment-terms">Payment Terms</label>
                                                                        <span>{rfqData[i].paymentTerms}</span>
                                                                        {showTags && ((lastBuyerCounter.terms ?? "") !== (lastVendorCounter.terms ?? "")) && 
                                                                        <span className="buyercounter-value">Buyer countering to: {rfqData[0].lastBuyerCounter.terms}</span>}
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row className="address-wrapper">
                                                            <Col sm="4">
                                                                <label>Delivery Location</label>
                                                                <address>
                                                                    <span>{rfqData[i].location.locationCode}</span>
                                                                    <span>{rfqData[i].location.name}</span>
                                                                    <span>{rfqData[i].location.addressLine1}</span>
                                                                    <span>{rfqData[i].location.addressLine2}</span>
                                                                    <span>{rfqData[i].location.city}</span>
                                                                    <span>{rfqData[i].location.stateProvince}</span>
                                                                    <span>{rfqData[i].location.postalCode}</span>
                                                                    <span>{rfqData[i].location.locationPhone}</span>
                                                                </address>
                                                            </Col>
                                                            <Col sm="2">
                                                                <label>Rail Siding</label>
                                                                <address>{rfqData[i].railSiding}</address>
                                                            </Col>
                                                            <Col sm="2">
                                                                <label>Properties</label>
                                                                <ul className="m-0 properties">
                                                                    {vendorNotes.map((item: any) => {
                                                                        return (
                                                                            item ? <li>{item}</li>: ''
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </Col>
                                                            <Col sm="4">
                                                                <div className="notes-wrapper">
                                                                    {rfqData[i].notesToVendor || rfqData[i].emailNotesToVendor ? (<><label className="notes">Notes To Vendor</label>
                                                                        <div className="buyer-comments">
                                                                            <ul>
                                                                                {rfqData[i].notesToVendor ? <li>{rfqData[i].notesToVendor}</li> : ''}
                                                                                {rfqData[i].emailNotesToVendor ? <li>{rfqData[i].emailNotesToVendor}</li> : ''}
                                                                                {/* <Popup
                                                                                    className="quote-popup"
                                                                                    content={"Helloo"}
                                                                                    trigger={<div className="buyer-comments"></div>} 
                                                                                /> */}
                                                                            </ul>
                                                                        </div></>
                                                                    ): ''}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <div className="table-wrapper mb-4 pb-2">
                                                            <div className="table">
                                                                <Table celled striped>
                                                                    <Table.Header className="main-head">
                                                                        <Table.Row>
                                                                            <Table.HeaderCell width={5}>SKU</Table.HeaderCell>
                                                                            <Table.HeaderCell width={5}>Product</Table.HeaderCell>
                                                                            <Table.HeaderCell>Length</Table.HeaderCell>
                                                                            <Table.HeaderCell>Units</Table.HeaderCell>
                                                                            <Table.HeaderCell>Qty/Unit</Table.HeaderCell>
                                                                            <Table.HeaderCell>No Quote</Table.HeaderCell>
                                                                            <Table.HeaderCell width={3} className="text-right">Price<sup>*</sup> </Table.HeaderCell>
                                                                            <Table.HeaderCell width={2}>Comments</Table.HeaderCell>
                                                                        </Table.Row>
                                                                    </Table.Header>
                                                                    <Table.Body>
                                                                        {responseCounterState.CounterDetails.map((row: ResponseCounterDetail, index: any) => {
                                                                           
                                                                            let lastBuyerCounterDetailLine = rfqData[0].lastBuyerCounter?.counterDetails?.$values?.find((m:any) => m.rfqLineItemId === row.RfqLineItemId);
                                                                            let lastVendorCounterDetailLine = rfqData[0].lastVendorCounter?.counterDetails?.$values?.find((m:any) => m.rfqLineItemId === row.RfqLineItemId);
                                                                            console.log('responseCounterState.CounterDetails', index, responseCounterState.CounterDetails, lastBuyerCounterDetailLine, lastVendorCounterDetailLine);

                                                                            return (
                                                                                <Table.Row key={index}>
                                                                                    <Table.Cell key={index + "1"}>{row.Sku}</Table.Cell>
                                                                                    <Table.Cell key={index + "2"}>{row.ProductDescription}</Table.Cell>
                                                                                    <Table.Cell key={index + "3"}>{row.ProductLength}</Table.Cell>
                                                                                    <Table.Cell key={index + "4"}>
                                                                                        <input  key={"units-"+loadId+"-"+index}  name="Units" value={row.Units || responseCounterDetailDefaultValue.Units} onChange={(event) => handleInputChange({ event, row }, index)} />
                                                                                        {showTags && (lastBuyerCounterDetailLine.units !== lastVendorCounterDetailLine.units) && 
                                                                                        <span className="buyercounter-value">Buyer countering to: {lastBuyerCounterDetailLine.units}</span>}
                                                                                    </Table.Cell>
                                                                                    <Table.Cell key={index + "5"}>
                                                                                        <input  key={"qtyOfUnits-"+loadId+"-"+index}  name="QtyOfUnits" value={row.QtyOfUnits || responseCounterDetailDefaultValue.QtyOfUnits} onChange={(event) => handleInputChange({ event, row }, index)} />
                                                                                        {showTags && (lastBuyerCounterDetailLine.qtyOfUnits !== lastVendorCounterDetailLine.qtyOfUnits) && 
                                                                                        <span className="buyercounter-value">Buyer countering to: {lastBuyerCounterDetailLine.qtyOfUnits}</span>}
                                                                                    </Table.Cell>
                                                                                    <Table.Cell key={index + "6"}>
                                                                                        <label className="quote-checkbox custom-checkbox d-flex align-items-center justify-content-center">
                                                                                            <input
                                                                                                key={"noQuote-"+loadId+"-"+index}
                                                                                                type="checkbox"
                                                                                                name="NoQuote"
                                                                                                checked={(row.NoQuote || responseCounterDetailDefaultValue.NoQuote)}
                                                                                                onChange={(event) => {
                                                                                                    handleInputChange(event, index);
                                                                                                }}
                                                                                                required
                                                                                            />
                                                                                            <span className="indicator"></span>
                                                                                        </label>
                                                                                        {showTags && ((lastBuyerCounterDetailLine.noQuote?.toString() ?? "") !== (lastVendorCounterDetailLine.noQuote?.toString() ?? "")) &&
                                                                                        <span className="buyercounter-value">Buyer countering to: {lastBuyerCounterDetailLine.noQuote.toString() == true ? "Checked" : "Unchecked"}</span>}
                                                                                    </Table.Cell>
                                                                                    <Table.Cell key={index + "7"}>
                                                                                        <div className="price-wrapper">
                                                                                            <input type="text" key={"price-"+loadId+"-"+index} required name="Price" autoFocus={index === 0} ref={(ref) => handleRef(ref, index)} 
                                                                                            className={row.NoQuote === true ? 'text-align-right checked' : 'text-align-right unchecked'} 
                                                                                            value={row.Price} onKeyDown={(event) => handleKeyDown(event, index)} onChange={(event) => { handleInputChange({ event, row }, index) }} />
                                                                                            
                                                                                            {showTags &&
                                                                                                <div className="show-wrapper">
                                                                                                    <Popup
                                                                                                        className="quote-popup"
                                                                                                        // open 
                                                                                                        content={
                                                                                                            <span>
                                                                                                                Original Quote: {lastVendorCounterDetailLine.price} <br />
                                                                                                                Counter: {lastBuyerCounterDetailLine.price} <br />
                                                                                                                2nd Quote: {quote}
                                                                                                            </span>
                                                                                                        }
                                                                                                        trigger={<FontAwesomeIcon icon={faCircleInfo} />} />
                                                                                                        {true && (lastBuyerCounterDetailLine.price !== lastVendorCounterDetailLine.price) &&
                                                                                                        <span className="buyercounter-value">Buyer countering to: {lastBuyerCounterDetailLine.price}</span>


                                                                                                        }
                                                                                                </div>
                                                                                            }

                                                                                        </div>
                                                                                    </Table.Cell>
                                                                                    <Table.Cell key={index + "8"}>
                                                                                        <input  key={"productNotes"+loadId}  className="text-align-right" name="ProductNotes" defaultValue={row.ProductNotes || ''} onChange={(event) => handleInputChange({ event, row }, index)} />
                                                                                    </Table.Cell>
                                                                                </Table.Row>
                                                                            )
                                                                        })}
                                                                    </Table.Body>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                        <Row className="date-wrapper" key={rfqId+'-data-wrapper'}>
                                                            <Col sm="3">
                                                                <Row>
                                                                    <Col sm="6">
                                                                        <label htmlFor="">Shipping Date<sup>*</sup> </label>
                                                                        <input 
                                                                            key={"shipDate"+loadId} 
                                                                            tabIndex={-1} 
                                                                            type="date" 
                                                                            ref={shipDateInput} 
                                                                            name="ShipDate" 
                                                                            value={shipDate} 
                                                                            onKeyDown={(event) => handleShipDateKeyDown(event)}
                                                                            onChange={(event) => handleHeaderInputChange(event)} 
                                                                        />
                                                                        {showTags && ((lastBuyerCounter.shipDate ?? "") !== (lastVendorCounter.shipDate ?? "")) && 
                                                                        <span className="buyercounter-value">Buyer countering to: {buyerdates.shipBuyerDate}</span>}
                                                                    </Col>
                                                                    <Col sm="6">
                                                                        <label htmlFor="">Delivery Date<sup>*</sup></label>
                                                                        <input 
                                                                            key={"deliveryDate"+loadId} 
                                                                            tabIndex={1} 
                                                                            type="date" name="DeliveryDate" 
                                                                            ref={deliveryDates} 
                                                                            value={deliveryDate} 
                                                                            onKeyDown={(event) => handleDeliveryDateKeyDown(event)}
                                                                            onChange={(event) => handleHeaderInputChange(event)} />
                                                                        {showTags && ((lastBuyerCounter.deliveryDate ?? "") !== (lastVendorCounter.deliveryDate ?? "")) && 
                                                                        <span className="buyercounter-value">Buyer countering to: {buyerdates.deliveryBuyerDate}</span>}
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col sm="3">
                                                                <label htmlFor="Mill">Mill / Producer</label>
                                                                {true && 
                                                                <input key={"mill"+loadId} tabIndex={2} name="Mill" value={responseCounterState.Mill || responseCounterDefaultValue.Mill} 
                                                                    onChange={(event) => handleHeaderInputChange(event)} 
                                                                    ref={millRef}
                                                                    //ref={(ref) = handleRef(ref)}
                                                                    />
                                                                }
                                                                {showTags && ((lastBuyerCounter.mill ?? "") !== (lastVendorCounter.mill ?? "")) && 
                                                                <span className="buyercounter-value">Buyer countering to: {rfqData[0].lastBuyerCounter.mill}</span>}
                                                            </Col>
                                                            <Col sm="2">
                                                                <label htmlFor="NumberOfLoads">Number of loads</label>
                                                                <input type="number" className={responseCounterState.NumberOfLoads > 1 ? 'load-input' : ''} key={"numberOfLoads-"+loadId} tabIndex={-1} name="NumberOfLoads" value={responseCounterState.NumberOfLoads || responseCounterDefaultValue.NumberOfLoads} onChange={(event) => handleHeaderInputChange(event)} />
                                                                {showTags && ((lastBuyerCounter.numberOfLoads ?? "") !== (lastVendorCounter.numberOfLoads ?? "")) && 
                                                                <span className="buyercounter-value">Buyer countering to: {rfqData[0].lastBuyerCounter.numberOfLoads}</span>}
                                                            </Col>
                                                            <Col sm="4">
                                                                <label htmlFor="NotesToBuyer">Notes to buyer</label>
                                                                <input key={"notesToBuyer-"+loadId} tabIndex={-1} name="NotesToBuyer" value={responseCounterState.NotesToBuyer || responseCounterDefaultValue.NotesToBuyer} onChange={(event) => handleHeaderInputChange(event)} />
                                                                {showTags && ((lastBuyerCounter.notesToBuyer ?? "") !== (lastVendorCounter.notesToBuyer ?? "")) && 
                                                                <span className="buyercounter-value">Buyer Counter Note: '{rfqData[0].lastBuyerCounter.notesToBuyer}'</span>}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        {
                            requote ?
                                <div className="button-wrapper">
                                    <Button tabIndex={-1} name="cancel-btn" className="cancel-btn" onClick={handleCloseModal}>
                                        CANCEL
                                    </Button>
                                    <Button tabIndex={-1} type="submit" name="reject-btn" className="reject-btn"
                                        // disabled
                                        disabled={disableUpdateButtons} 
                                        onClick={handleSubmit}
                                    >
                                        REJECT COUNTER
                                    </Button>
                                    <Button tabIndex={3} type="submit" name="requote-btn" className="requote-btn"
                                        // disabled
                                        disabled={disableUpdateButtons} 
                                        onClick={handleSubmit}
                                    >
                                        REQUOTE
                                    </Button>
                                    <Button tabIndex={4} type="submit" name="accept-btn" className="confirm-btn"
                                        // disabled
                                        disabled={disableUpdateButtons} 
                                        onClick={handleSubmit}
                                    >
                                        ACCEPT ALL
                                    </Button>
                                </div>
                                :
                                <div className="button-wrapper">
                                    <Button tabIndex={-1} className="cancel-btn" onClick={handleCloseModal}>
                                        CANCEL
                                    </Button>
                                    <Button key={Math.random()} tabIndex={3} type="submit" className="submit-btn"
                                        disabled={disabled || disableUpdateButtons}
                                        onClick={handleSubmit}
                                    >
                                        SUBMIT QUOTE
                                    </Button>
                                </div>
                        }
                    </Modal.Actions>
                </Modal>
            </form>
        </div>
    );
}

export default RfqModalComponent;