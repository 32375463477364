import React, { useState } from "react";
import { useEffect} from "react";
import { Dropdown } from 'semantic-ui-react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import { Route, Navigate, useNavigate } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { ApiRequest } from "../../api/ApiAxios";
//import {AxiosError, AxiosResponse } from 'axios';
// import { ApiRequest } from "../../api/ApiAxios"
import Login from "../../components/Login";


const Header: React.FC = () => {

    const navigate = useNavigate(); //redirect to login   
    const logMeOut = () => {
        console.log('in logMeOut');
        const response =  ApiRequest.getRequest("security/logout") ;
        console.log('logout response', response);
        console.log('navigating to login');
        navigate("Login");
     }
     //cns: was only working for localhost. Need to troubleshoot.
     const getCookie = (name:string) => { 
        var re = new RegExp(name + "=([^;]+)"); 
        var value = re.exec(document.cookie); 
        return (value != null) ? unescape(value[1]) : 'N/A'; 
        //return value[1] ?;
    }
    const getUserName = () => {
        var value = localStorage.getItem('UserDisplayName');
        return (value != null) ? value : 'N/A';
    }

    //no parameters need to be set as a default
    let postData = {}
    
    const [status, setStatus] = useState();

    //chris:  is this necessary? 
    // useEffect(() => {
    //     let requestUrl = 'WorkList/Search';
    //     (async () => {
    //         const requestOptions = {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         };
    //         const response = await ApiRequest.postRequest(requestUrl, postData, requestOptions);
    //         if (response == null ){
    //             navigate("Login");
    //         }
    //         if (response?.status) {
    //             setStatus(response.status);

    //             if (typeof response?.status !== 'undefined' && response.status === 401) {
    //                 console.log("navigating to login");
    //                 navigate("Login");
    //             }
   
    //         }
    //     })();
    // },[status]);
    

    return (
        <div className="Header">
            <header className="App-header">
                {/* <Container> */}
                    <Row className="align-items-center p-0 justify-content-between">
                            <Col sm="3">
                                <Link to ="/"><img src="images/Logo.png" alt="Logo" /></Link>
                            </Col>
                            <Col sm="3">
                                <h1>VENDORS</h1>
                            </Col>
                            <Col sm="3">
                                <div className="login-wrapper">
                                    <span className="dropdown-link d-flex align-items-center justify-content-end">
                                        <img src="images/Icon.png" alt="Logo" />
                                        <Dropdown title="Account" text={getUserName()}>
                                            <Dropdown.Menu>
                                                <Dropdown.Item><Link to="/account">Account</Link></Dropdown.Item>
                                                <Dropdown.Item><Link to="/changepassword">Change Password</Link></Dropdown.Item>
                                                <Dropdown.Item text='Logout' onClick={logMeOut} /> 
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </span>
                                </div>
                            </Col>
                    </Row>
                {/* </Container> */}
            </header>
        </div>
    );
}

export default Header;
