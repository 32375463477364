import React from "react";
import { useState, useEffect } from "react";
import { AxiosError } from 'axios';
import { ApiRequest } from "../api/ApiAxios"
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ShowHidePassword from "./Inputs/ShowHidePassword";

const ChangePassword: React.FC = () => {

    let navigate: NavigateFunction = useNavigate();

    const [userMessage, setUserMessage] = useState<string>("Change your password.");
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");

    const initialValues: {
        UserPassword: string,
        UserPasswordConfirm: string,
        RememberPassword: boolean
    } = {
        UserPassword: "",
        UserPasswordConfirm: "",
        RememberPassword: false
    };

    const validationSchema = Yup.object().shape({
        UserPassword:  Yup.string()
        .min(8, "Must Contain at least 8 characters")
        .matches(/^(?=.*\d)/, "Must Contain at least one number"),
        UserPasswordConfirm: Yup.string()
            .required("This field is required!")
            .oneOf([Yup.ref('UserPassword'), ""], 'Confirm Password does not match'),
        RememberPassword: Yup.bool().default(false)
    });

    // const validationSchema2 = Yup.object().shape({
    //     Email: Yup.string().required("This field is required!"),
    //     FirstName: Yup.string().required("This field is required!"),
    //     LastName: Yup.string().required("This field is required!"),
    //     Company: Yup.string().required("This field is required!"),
    //     Phone: Yup.string().required("This field is required!"),
    //     Password: Yup.string().required("This field is required!"),
    //     ConfirmPassword: Yup.string()
    //         .required("This field is required!")
    //         .oneOf([Yup.ref('Password'), ''], 'Confirm Password does not match'),
    // });


    useEffect(() => {

        //will authenticate a user with email and code - if not provided, user will need to already be authenticated

        const hasEmail = searchParams.has("email");
        const hasCode = searchParams.has("code");

        if (hasEmail && hasCode) {
            let requestUrl = 'security/VerifyResponseChangePassword';

            (async () => {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };
    
                const postData = {
                    "Email": searchParams.get("email"),
                    "Code": searchParams.get("code")
                };
                console.log('login request activate',requestUrl, postData);
               
                const response = await ApiRequest.postRequest(requestUrl, postData, requestOptions);

               setUserMessage(response?.userMessage || 'There was an error when activating account.  Contact support.');
            })();
   
        }


    },[]);

    const handleOnCancel = (event:any) => {
        console.log('change password, oncancel', event);
        navigate("/login");
    }

    const handleChangePasswordSubmit = (formValue: { UserPassword: string; UserPasswordConfirm: string; RememberPassword: boolean;}) => {
        const { UserPassword, UserPasswordConfirm, RememberPassword } = formValue;
        console.log('handleChangePasswordSubmit', formValue);
        //console.log('handlechange', event);
   
        try {

            let requestUrl = 'security/changepassword';
            (async () => {
                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                };
                const data = {
                    UserPassword: UserPassword,
                    UserPasswordConfirm: UserPasswordConfirm
                };
                const response = await ApiRequest.postRequest(requestUrl, data, options);
                console.log('login response', response);


                if (response.status === 401) {
                    console.log("login unsuccessful");
                } else {
                    console.log('successful change?', response.passwordChanged);
                    if (response.passwordChanged) {
                        //user is authenticated, navigate to root and go to default
                        navigate("/");
                    }
                    // save displayname in local storage
                    // localStorage.setItem('UserDisplayName', response.appUser.displayName);
                    // localStorage.getItem('UserDisplayName');
                    // console.log('login - about to navigate to root1', response.appUser.displayName);
                    // navigate("/");
                }


            })();


        } catch (error) {
            let err = error as AxiosError
            console.log('error', err);
        }


    };

    return (
        <div id="register-page" className="login-page change-password">
            <div className="form-bg-wrapper">
                <div className="formbox-wrapper d-flex justify-content-center">
                    <div className="inner-form">
                        <div className="d-flex justify-content-between align-items-baseline flex-wrap">
                            <h1>VENDOR PORTAL</h1>
                            <div className="img-wrapper text-center">
                                <img src="images/form-logo.svg" alt="" />
                            </div>
                        </div>
                        <h2>RESET PASSWORD</h2>
                        <span className="error-message">{userMessage}</span>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleChangePasswordSubmit}
                        >
                            <Form>
                                <div className="form-group mb-2">
                                    <label htmlFor="UserPassword">Password</label>
                                    <Field id="UserPassword" name="UserPassword" type="password"/>
                                    <ErrorMessage name="UserPassword" className="error-message" component="label" />
                                </div>
                                <div className="form-group mb-2">
                                    <label htmlFor="UserPasswordConfirm">Confirm Password</label>
                                    <Field id="UserPasswordConfirm" name="UserPasswordConfirm" type="password" />
                                    <ErrorMessage name="UserPasswordConfirm" className="error-message" component="label" />
                                </div>
                                <div className="button-wrapper d-flex justify-content-end align-items-center mb-3 pb-1">
                                    <button type="button" className="cancel-btn" onClick = {(event) => handleOnCancel(event)}>
                                        CANCEL
                                    </button>
                                    <button id="SubmitForm" name="SubmitForm" type="submit" disabled={false} className="login-btn" 
                                        //onClick={(formValues: any) => handleChangePasswordSubmit(formValues)}
                                     >
                                        RESET & LOGIN
                                    </button>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
            <footer className="login-footer">
                <div className="log-wrapper">
                    <img src="images/login-logo.svg" alt="" />
                </div>
                <a href="javascript:">TERMS AND CONDITIONS</a>
                <span className="d-block">© 2018 Builders FirstSource, Inc. All rights reserved.</span>
            </footer>
        </div>
    );
}

export default ChangePassword;