import React from "react";
import { useState } from "react";
import { AxiosError } from 'axios';
import { ApiRequest } from "../api/ApiAxios"
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ShowHidePassword from "./Inputs/ShowHidePassword";
import { Dropdown, Table, Modal, Input, Button, Label} from 'semantic-ui-react';

const Register: React.FC = () => {

    let navigate: NavigateFunction = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [userMessage, setUserMessage] = useState<string>("");

    const initialValues: {
        Email: string;
        Password: string,
        FirstName: string,
        LastName: string,
        Company: string,
        Phone: any,
        NotifyRfq: boolean,
        NotifyRFQCounter: boolean,
        NotifyPO: boolean,
        Active: boolean,
    } = {

        Email: "",
        Password: "",
        FirstName: "",
        LastName: "",
        Company: "",
        Phone: "",
        NotifyRfq: false,
        NotifyRFQCounter: false,
        NotifyPO: false,
        Active: true,
    };

    const validationSchema = Yup.object().shape({
        Email: Yup.string().required("This field is required!"),
        FirstName: Yup.string().required("This field is required!"),
        LastName: Yup.string().required("This field is required!"),
        Company: Yup.string().required("This field is required!"),
        Phone: Yup.string().required("This field is required!"),
        Password:  Yup.string()
        .min(8, "Must Contain at least 8 characters")
        .matches(/^(?=.*\d)/, "Must Contain at least one number"),
        ConfirmPassword: Yup.string()
            .required("This field is required!")
            .oneOf([Yup.ref('Password'), ''], 'Confirm Password does not match'),
    });

    const handleRegister = (formValue: { Email: string; Password: string; FirstName: string; LastName: string; Company: string; Phone: any; NotifyRfq: boolean;
        NotifyRFQCounter: boolean;
        NotifyPO: boolean;
        Active: boolean;}) => {
        const { Email, Password, FirstName, LastName, Company, Phone, NotifyRfq, NotifyRFQCounter, NotifyPO, Active } = formValue;
        console.log('handleRegister', formValue)

        try {
            
            setUserMessage("");

            let requestUrl = 'security/createaccount';

            (async () => {
                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                };
                const response = await ApiRequest.postRequest(requestUrl, formValue, options);
                console.log('login response', response.userMessage, response);

                if (response.status === 401) {
                    console.log("Not Authorized - 401 Error");
                    // } else if (response.userMessage !== '') {
                    //     setUserMessage(response.userMessage);
                } else {
                    setUserMessage(response.userMessage);
                    setLoading(true);
                    //REDIRECT TO LOGIN PAGE OR LOGIN CONFIRMATION PAGE.
                    // save displayname in local storage
                    // localStorage.setItem('UserDisplayName', response.appUser.displayName);
                    // localStorage.getItem('UserDisplayName');
                    // console.log('login - about to navigate to root1', response.appUser.displayName);
                    // navigate("/");
                }

            })();


        } catch (error) {
            let err = error as AxiosError
            console.log('error', err);
        }


    };

    return (
        <div id="register-page" className="login-page">
            <div className="form-bg-wrapper">
                <div className="formbox-wrapper d-flex justify-content-center">
                    <div className="inner-form">
                        <div className="d-flex justify-content-between align-items-baseline flex-wrap">
                            <h1>VENDOR PORTAL</h1>
                            <div className="img-wrapper text-center">
                                <img src="images/form-logo.svg" alt="" />
                            </div>
                        </div>
                        <h2 className="text-center">CREATE ACCOUNT</h2>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleRegister}
                        >
                            <Form>
                                <Row>
                                    <Col sm="6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="firstname">First Name</label>
                                            <Field tabIndex="1" name="FirstName" type="text" />
                                            <ErrorMessage name="FirstName" className="error-message" component="label" />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="company">Company</label>
                                            <Field tabIndex="3" name="Company" type="text" />
                                            <ErrorMessage name="Company" className="error-message" component="label" />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="Email">Email Address</label>
                                            <Field name="Email" type="email" />
                                            <ErrorMessage name="Email" className="error-message" component="label" />
                                        </div>
                                        <div className="form-group mb-1">
                                            <label htmlFor="Password">Password</label>
                                            <ShowHidePassword name="Password"/>
                                        </div>
                                        <div className="form-group mb-2">
                                            <label htmlFor="ConfirmPassword">Confirm Password</label>
                                            <ShowHidePassword name="ConfirmPassword"/>
                                        </div>
                                        {/* <div className="form-group text-right mb-3">
                                            <a href="javascript:" className="text-right">Forgot Password?</a>
                                        </div> */}
                                    </Col>
                                    <Col sm="6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="lastname">Last Name</label>
                                            <Field tabIndex="2" name="LastName" type="text" />
                                            <ErrorMessage name="LastName" className="error-message" component="label" />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="phone">Phone</label>
                                            <Field tabIndex="4" name="Phone" type="text" />
                                            <ErrorMessage name="Phone" className="error-message" component="label" />
                                        </div>
                                        <div className="checkbox-wrapper">
                                            <label htmlFor="rfq-email" className="custom-checkbox d-flex align-items-center">
                                                <Field id="rfq-email" type="checkbox" name="NotifyRfq"/>
                                                <span className="indicator"></span>
                                                <span className="label-text">New RFQ Email Notifications</span>
                                            </label>
                                            <label htmlFor="rfq-counter" className="custom-checkbox d-flex align-items-center">
                                                <Field id="rfq-counter" type="checkbox" name="NotifyRFQCounter"/>
                                                <span className="indicator"></span>
                                                <span className="label-text">RFQ Counter Email Notifications</span>
                                            </label>
                                            <label htmlFor="po-email" className="custom-checkbox d-flex align-items-center">
                                                <Field id="po-email" type="checkbox" name="NotifyPO" />
                                                <span className="indicator"></span>
                                                <span className="label-text">PO Email Notifications</span>
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                                <span className="error-message">{userMessage}</span>
                                <div className="button-wrapper d-flex justify-content-end align-items-center">
                                    <button type="button" className="cancel-btn" onClick={(e) => navigate("/")}>
                                        CANCEL
                                    </button>
                                    <button type="submit" disabled={loading} className="login-btn">
                                        CREATE ACCOUNT
                                    </button>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
            <div className="empty-blocker"></div>
            <footer className="login-footer">
                <div className="log-wrapper">
                    <img src="images/login-logo.svg" alt="" />
                </div>
                <a href="javascript:">TERMS AND CONDITIONS</a>
                <span className="d-block">© 2018 Builders FirstSource, Inc. All rights reserved.</span>
            </footer>
        </div>
    );
}

export default Register;