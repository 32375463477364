import React from "react";
import { useState} from "react";
import {Field, ErrorMessage } from "formik";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';

type Props = {
  name: any;
};

const ShowHidePassword: React.FC<Props> = ({
  name,
}) => {

    const [isVisible, setVisible] = useState(false);
  
    const toggle = () => {
      setVisible(!isVisible);
    };
  
    return (
      <div className="form-group">
        <div className="d-flex align-items-center inner-wrapper">
          <Field type={!isVisible ? "password" : "text"} name={name} />
          <span className="icon" onClick={toggle}>
            {isVisible ? <FontAwesomeIcon icon={faEye} /> :  <FontAwesomeIcon icon={faEyeSlash} />}
          </span>
        </div>
        <ErrorMessage name={name} className="error-message" component="label" />
      </div>
    );
  }

  export default ShowHidePassword;