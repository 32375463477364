import { useTable, useFilters, useSortBy } from "react-table";
import { useEffect } from 'react';
import { Dropdown, Loader } from 'semantic-ui-react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { DefaultFilterForColumn } from "./Filter";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import PerfectScrollbar from 'react-perfect-scrollbar';

const TableFilter = ({ columns, data, count, getIndex, getRows, response }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // updateRow
  } = useTable(
    {
      columns,
      data,
      count,
      defaultColumn: { Filter: DefaultFilterForColumn },
    },
    useFilters,
    useSortBy
  );


  // For getting Row Index 
  const handleClick = (rowIndex) => {
    getIndex(rowIndex);
  }

  useEffect(() => {
    getRows(rows);
  },[rows.length])

  const exportPdf = () => {
    const doc = new jsPDF("landscape");
    var totalPagesExp = "{total_pages_count_string}";

    doc.autoTable({ html: '#table-pdf' })

    if (typeof doc.putTotalPages === "function") {
      doc.putTotalPages(totalPagesExp);
    }

    if (typeof doc.putTotalPages === "function") {
      doc.putTotalPages(totalPagesExp);
    }

    doc.save("RFQ.pdf");
  }

  function generateWorksheetData() {
    const worksheetData = [];
    worksheetData.push(columns.map((column) => column.Header));
    data.forEach((row) => {
      worksheetData.push(columns.map((column) => row[column.accessor]));
    });
    return worksheetData;
  }

  function fitToColumn(arrayOfArray) {
    return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i] ? a2[i].toString().length : 0)) + 1 }));
  }
  const handleExport = () => {
    const worksheetData = generateWorksheetData();
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    worksheet['!cols'] = fitToColumn(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([excelBuffer], { type: 'application/octet-stream' }), 'RFQ.xlsx');
  }

  return (
    <div className="table-container">
      <Dropdown
        text='EXPORT'
        button
        className='dropdown-button'
        disabled={true}
      >
        <Dropdown.Menu>
          <Dropdown.Item
            text='RFQ as PDF'
          />
          <Dropdown.Item
            text='RFQ as Excel'
          >
          </Dropdown.Item>
          <Dropdown.Item
            text='PO as PDF'
          />
          <Dropdown.Item
            text='PO as Excel'
          />
        </Dropdown.Menu>
      </Dropdown>
      <PerfectScrollbar>
        <table id="table-pdf" {...getTableProps()}>
          <thead id="table-pdf-thead">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th width="11.11%" className="main-head">
                    <tr>
                      <th>
                        <div
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          className={
                            column.isSorted
                              ? column.isSortedDesc
                                ? "table-heading desc"
                                : "table-heading asc"
                              : "table-heading"
                          }>
                          {column.render("Header")
                          }
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th>
                        <div>
                          {column.canFilter ? column.render("Filter") : null}
                        </div>
                      </th>
                    </tr>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {
            response.length === 0 ? (
              <div className="loader-wrapper">
                <Loader active inline='centered' />
              </div>
            ) : (
              rows.length === 0 ? (
                <tr>
                  <td colSpan={9} style={{ border: 'none' }}><div className="empty-data text-center p-4">No Data Found</div></td>
                </tr>
              ) : (
                rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, column) => {
                        return <td {...cell.getCellProps()} onClick={() => handleClick(row.index, cell.column.id)}>{cell.render("Cell")}</td>;
                      })}
                    </tr>
                  );
                })
              )
            )
            }
          </tbody>
        </table>
      </PerfectScrollbar>
    </div>
  );

}

export default TableFilter;