import React, { useRef } from "react";
import { useState, useMemo, Fragment, useEffect } from "react";
import { useAsyncDebounce } from "react-table";
import { Input } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faCaretDown, faFilter } from '@fortawesome/free-solid-svg-icons';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Component for Global Filter
export function GlobalFilter({ globalFilter, setGlobalFilter }) {
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
}

// Component for Default Column Filter

export function DefaultFilterForColumn({
  column: {
    filterValue,
    preFilteredRows: { length },
    setFilter,
  },
}) {

  const [empty, setEmpty] = useState(false);

  const clearValue = () => {
    setEmpty(false);
    setFilter('');
  }

  return (
    <div className="input-field">
      <div className="filter-icon">{filterValue ? <FontAwesomeIcon icon={faFilter} /> : ''}</div>
      <Input
        type="text"
        className="searchable"
        value={filterValue || ""}
        onChange={(e) => {
          let val = e.target.value;
          setFilter(val || undefined);
          console.log(filterValue)
          if (val.length > 0) { setEmpty(true); } else { setEmpty(false); }
        }}
      />
      {empty && (
        <button className="reset-btn" onClick={clearValue} ><FontAwesomeIcon icon={faTimesCircle} /></button>
      )
      }
    </div>
  );
}



// Component for MultiSelect Filter

export const MultipleFilter = (rows, filler, filterValue) => {
  const arr = [];
  rows.forEach((val) => {
    if (filterValue.includes(val.original.rfqBuyerName)) arr.push(val);
    if (filterValue.includes(val.original.rfqStatus)) arr.push(val);
    if (filterValue.includes(val.original.poStatus)) arr.push(val);
  });
  // console.log(arr);
  return arr;
};

var values = '';
function setFilteredParams(filterArr, val) {
  //   console.log(filterArr);
  // console.log(val);
  // if (val === undefined) return undefined;
  if (filterArr.includes(val)) {
    filterArr = filterArr.filter((n) => {
      return n !== val;
    });
  } else filterArr.push(val);
  // console.log(filterArr);
  values = filterArr;
  if (filterArr.length === 0) filterArr = undefined;
  return filterArr;
}

export function SelectColumnFilter({
  count, column: { filterValue = [], setFilter, preFilteredRows, id }
}) {
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  const ref = useRef()
  const [isOpen, setOpen] = useState(false);
  const [userinfo, setUserInfo] = useState({
    users: [],
    response: [],
  });

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setOpen(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isOpen])

  const [checkboxes, setCheckboxes] = useState();
  const [inputValues, setInputValues] = useState([]);

  useEffect(() => {
    const storedValue = localStorage.getItem(id);
    if (storedValue) {
      var arraySplit = storedValue.split(",");
      if (arraySplit !== null && arraySplit !== "") {
        setCheckboxes(storedValue);
        setFilter(arraySplit);
        setInputValues(arraySplit);
        console.log(arraySplit);
        console.log(count);
        if (count >= 0) {
          arraySplit.map(m => {
            var checkbox = document.querySelector(`input[value="${m}"]`);
            if (checkbox !== null) {
              console.log("CHECED VALUES", m);
              console.log(checkbox);
              // checkbox.checked= true;
              setCheckedValues((prevValues) => [...prevValues, checkbox.value]);
            }
          })
        }
      }
    }
  }, [count])

  const [checkedValues, setCheckedValues] = useState([]);

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setFilter(setFilteredParams(filterValue, value));
    setOpen(false);
    localStorage.setItem(id, values);
    setInputValues(values);
    console.log(values);
    if (event.target.checked) {
      setCheckedValues((prevValues) => [...prevValues, value]);
    }
    else {
      setCheckedValues((prevValues) => prevValues.filter(item => item !== value));
    }
  }

  return (

    <div className="dropdown-container" ref={ref}>
      <div className="filter-icon">{inputValues.length > 0 ? <FontAwesomeIcon icon={faFilter} /> : ''}</div>
      <div className="dropdown-link">
        <Input
          readOnly
          type="search"
          className="select-container"
          placeholder="Select"
          ref={ref}
          onClick={(e) => {
            setOpen(!isOpen);
          }}
        />
        <button onClick={(e) => { setOpen(!isOpen); }}>
          <FontAwesomeIcon icon={faCaretDown} />
        </button>
      </div>
      <div className={`dropdown-wrapper ${isOpen && 'open'}`}>
        <PerfectScrollbar>
          <ul>
            {(() => {
              if (id == "rfqBuyerName") {
                return (
                  <Fragment>
                    {options.map((option, i) => {
                      return (
                        <Fragment key={i}>
                          <li>
                            <label
                              htmlFor={option}
                              className="custom-checkbox"
                            >
                              <input
                                type="checkbox"
                                className="buyername"
                                id={option}
                                name={option}
                                value={option}
                                checked={checkedValues.includes(option)}
                                onChange={handleCheckboxChange}
                              ></input>
                              <span className='indicator'></span>
                              <span className="label-text">{option}</span>
                            </label>
                          </li>
                        </Fragment>
                      );
                    })}
                  </Fragment>
                );
              }
              else if (id == "rfqStatus") {
                return (
                  <Fragment>
                    <li>
                      <Fragment>
                        <label
                          htmlFor="NEW"
                          className="custom-checkbox"
                        >
                          <input
                            type="checkbox"
                            checked={checkedValues.includes("NEW")}
                            id="NEW"
                            name="NEW"
                            value="NEW"
                            onChange={(e) => {
                              setFilter(setFilteredParams(filterValue, e.target.value));
                              setOpen(false);
                              localStorage.setItem(id, values);
                              setInputValues(values);
                              if (e.target.checked) {
                                setCheckedValues((prevValues) => [...prevValues, e.target.value]);
                              }
                              else {
                                setCheckedValues((prevValues) => prevValues.filter(item => item !== e.target.value));
                              }
                            }}
                          ></input>
                          <span className='indicator'></span>
                          <span className="label-text">NEW</span>
                        </label>
                      </Fragment>
                    </li>
                    <li>
                      <Fragment>
                        <label
                          htmlFor="QUOTED"
                          className="custom-checkbox"
                        >
                          <input
                            type="checkbox"
                            checked={checkedValues.includes("QUOTED")}
                            id="QUOTED"
                            name="QUOTED"
                            value="QUOTED"
                            onChange={(e) => {
                              setFilter(setFilteredParams(filterValue, e.target.value));
                              setOpen(false);
                              localStorage.setItem(id, values);
                              setInputValues(values);
                              if (e.target.checked) {
                                setCheckedValues((prevValues) => [...prevValues, e.target.value]);
                              }
                              else {
                                setCheckedValues((prevValues) => prevValues.filter(item => item !== e.target.value));
                              }
                            }}
                          ></input>
                          <span className='indicator'></span>
                          <span className="label-text">QUOTED</span>
                        </label>
                      </Fragment>
                    </li>
                    <li>
                      <Fragment>
                        <label
                          htmlFor="COUNTERED"
                          className="custom-checkbox"
                        >
                          <input
                            type="checkbox"
                            checked={checkedValues.includes("COUNTERED")}
                            id="COUNTERED"
                            name="COUNTERED"
                            value="COUNTERED"
                            onChange={(e) => {
                              setFilter(setFilteredParams(filterValue, e.target.value));
                              setOpen(false);
                              localStorage.setItem(id, values);
                              setInputValues(values);
                              if (e.target.checked) {
                                setCheckedValues((prevValues) => [...prevValues, e.target.value]);
                              }
                              else {
                                setCheckedValues((prevValues) => prevValues.filter(item => item !== e.target.value));
                              }
                            }}
                          ></input>
                          <span className='indicator'></span>
                          <span className="label-text">COUNTERED</span>
                        </label>
                      </Fragment>
                    </li>
                    <li>
                      <Fragment>
                        <label
                          htmlFor="WITHDRAWN"
                          className="custom-checkbox"
                        >
                          <input
                            type="checkbox"
                            checked={checkedValues.includes("WITHDRAWN")}
                            id="WITHDRAWN"
                            name="WITHDRAWN"
                            value="WITHDRAWN"
                            onChange={(e) => {
                              setFilter(setFilteredParams(filterValue, e.target.value));
                              setOpen(false);
                              localStorage.setItem(id, values);
                              setInputValues(values);
                              if (e.target.checked) {
                                setCheckedValues((prevValues) => [...prevValues, e.target.value]);
                              }
                              else {
                                setCheckedValues((prevValues) => prevValues.filter(item => item !== e.target.value));
                              }
                            }}
                          ></input>
                          <span className='indicator'></span>
                          <span className="label-text">WITHDRAWN</span>
                        </label>
                      </Fragment>
                    </li>

                  </Fragment>
                );
              }
              else if (id == "poStatus") {
                return (
                  <Fragment>
                    <li>
                      <Fragment>
                        <label
                          htmlFor="created"
                          className="custom-checkbox"
                        >
                          <input
                            type="checkbox"
                            checked={checkedValues.includes("PO CREATED")}
                            id="created"
                            name="created"
                            value="PO CREATED"
                            onChange={(e) => {
                              setFilter(setFilteredParams(filterValue, e.target.value));
                              setOpen(false);
                              localStorage.setItem(id, values);
                              setInputValues(values);
                              if (e.target.checked) {
                                setCheckedValues((prevValues) => [...prevValues, e.target.value]);
                              }
                              else {
                                setCheckedValues((prevValues) => prevValues.filter(item => item !== e.target.value));
                              }
                            }}
                          ></input>
                          <span className='indicator'></span>
                          <span className="label-text">PO CREATED</span>
                        </label>
                      </Fragment>
                    </li>
                    <li>
                      <Fragment>
                        <label
                          htmlFor="Confirmed"
                          className="custom-checkbox"
                        >
                          <input
                            type="checkbox"
                            checked={checkedValues.includes("CONFIRMED")}
                            id="Confirmed"
                            name="Confirmed"
                            value="CONFIRMED"
                            onChange={(e) => {
                              setFilter(setFilteredParams(filterValue, e.target.value));
                              setOpen(false);
                              localStorage.setItem(id, values);
                              setInputValues(values);
                              if (e.target.checked) {
                                setCheckedValues((prevValues) => [...prevValues, e.target.value]);
                              }
                              else {
                                setCheckedValues((prevValues) => prevValues.filter(item => item !== e.target.value));
                              }
                            }}
                          ></input>
                          <span className='indicator'></span>
                          <span className="label-text">PO CONFIRMED</span>
                        </label>
                      </Fragment>
                    </li>
                    <li>
                      <Fragment>
                        <label
                          htmlFor="REJECTED"
                          className="custom-checkbox"
                        >
                          <input
                            type="checkbox"
                            checked={checkedValues.includes("REJECTED")}
                            id="REJECTED"
                            name="REJECTED"
                            value="REJECTED"
                            onChange={(e) => {
                              setFilter(setFilteredParams(filterValue, e.target.value));
                              setOpen(false);
                              localStorage.setItem(id, values);
                              setInputValues(values);
                              if (e.target.checked) {
                                setCheckedValues((prevValues) => [...prevValues, e.target.value]);
                              }
                              else {
                                setCheckedValues((prevValues) => prevValues.filter(item => item !== e.target.value));
                              }
                            }}
                          ></input>
                          <span className='indicator'></span>
                          <span className="label-text">PO REJECTED</span>
                        </label>
                      </Fragment>
                    </li>
                  </Fragment>
                );
              }
            })()}
          </ul>
        </PerfectScrollbar>
      </div>
    </div>
  );
}


// Component for Custom Datepicker Filter

export function dateBetweenFilterFn(rows, id, filterValues) {
  const sd = filterValues[0] ? new Date(filterValues[0]) : undefined;
  const ed = filterValues[1] ? new Date(filterValues[1]) : undefined;
  if (ed || sd) {
    var [years, months, days] = filterValues[0].split("-");
    var inputDate = [months, days, years].join("/");

    return rows.filter((r) => {
      // format data

      if (r.values[id] === undefined) {
        return undefined;
      }
      if (r.values[id] != null) {
        var dateAndHour = r.values[id].split(" ");
        var [year, month, day] = dateAndHour[0].split("-");
        var date = [month, day, year].join("/");
        var formattedData = date;


        const cellDate = new Date(formattedData);

        if (ed && sd) {
          return cellDate >= sd && cellDate <= ed;
        } else if (sd) {
          if (years === year) {
            return cellDate <= sd;
          }
        } else {
          return cellDate <= ed;
        }
      }
    });
  } else {
    return rows;
  }
}

export function DateRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length
      ? new Date(preFilteredRows[0].values[id])
      : new Date(0);
    let max = preFilteredRows.length
      ? new Date(preFilteredRows[0].values[id])
      : new Date(0);

    preFilteredRows.forEach((row) => {
      const rowDate = new Date(row.values[id]);

      min = rowDate <= min ? rowDate : min;
      max = rowDate >= max ? rowDate : max;
    });

    return [min, max];
  }, [id, preFilteredRows]);


  const [datesValue, setDatesValue] = useState();
  const [date, setDate] = useState();
  const [entered, setEntered] = useState(false);

  const handleReset = () => {
    setEntered(false);
    setDate('');
    setFilter('');
  }

  return (
    <div className="date-wrapper">
      <div className="filter-icon">{date ? <FontAwesomeIcon icon={faFilter} /> : ''}</div>
      <input
        type="date"
        onChange={(e) => {
          const val = e.target.value;
          if (val !== '') {
            let [yrs, mnts, dys] = val.split("-");
            let inputValue = [mnts, dys, yrs].join("/");
            console.log(inputValue);
            setEntered(true);
            setDate(e.target.value);
            setFilter((old = []) => [inputValue ? inputValue : undefined, old[1]]);
            setDatesValue(inputValue);
          }
          else {
            handleReset();
          }
        }}
        value={date}
      />
      {entered && (
        <button className="reset-btn" onClick={handleReset}><FontAwesomeIcon icon={faTimesCircle} /></button>
      )
      }
    </div>
  );
}