import React from "react";
import { useState, useEffect } from "react";
import { AxiosError } from 'axios';
import { ApiRequest } from "../api/ApiAxios";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { NavigateFunction, useNavigate } from 'react-router-dom';


const AccountVerifcation: React.FC = () => {

    let navigate: NavigateFunction = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [userMessage, setUserMessage] = useState<string>("");

    const initialValues: {
        EmailToVerify: string;
    } = {

        EmailToVerify: "",
    };

    const validationSchema = Yup.object().shape({
        EmailToVerify: Yup.string().required("This field is required!"),
    });

    const handleEmailSubmit = (formValue: { EmailToVerify: string;}) => {
        const EmailToVerify  = formValue;
        console.log('handleRegister', formValue)


        try {

            setUserMessage("");

            let requestUrl = 'security/ForgotPasswordSendVerificationEmail';
            (async () => {
                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                };
                const response = await ApiRequest.postRequest(requestUrl, formValue, options);
                console.log('login response', response.userMessage, response);

                if (response.status === 401) {
                    console.log("Not Authorized - 401 Error");
                    // } else if (response.userMessage !== '') {
                    //     setUserMessage(response.userMessage);
                } else {
                    setUserMessage(response.userMessage);
                    // REDIRECT TO LOGIN PAGE OR LOGIN CONFIRMATION PAGE.
                    // save displayname in local storage
                    // localStorage.setItem('UserDisplayName', response.appUser.displayName);
                    // localStorage.getItem('UserDisplayName');
                    // console.log('login - about to navigate to root1', response.appUser.displayName);
                    // navigate("/");
                }

            })();


        } catch (error) {
            let err = error as AxiosError
            console.log('error', err);
        }





    };

    //const navigate = useNavigate(); //redirect to login    
    useEffect(() => {
        try {
            console.log('userEffect - account verification');
            let requestUrl = 'WorkList/Search';

            const queryParams = new URLSearchParams(window.location.search);
            console.log("email", queryParams.get("email"), 'verificationcode', queryParams.get("verificationcode"));

            // (async () => {
            //     const requestOptions = {
            //         method: 'POST',
            //         headers: {
            //             'Content-Type': 'application/json',
            //         },
            //     };
            //     const response = await ApiRequest.postRequest(requestUrl, postData, requestOptions);
            //     console.log('showall response', response);

            //     let data = (response as AxiosResponse)?.data;

            //     if (typeof data?.rows?.$values !== 'undefined') {
            //         const rowData = data.rows.$values;
            //         const actionData = data.rows.$values.filter(({ rfqStatus, poStatus }: any) => rfqStatus == "COUNTERED" || rfqStatus == "NEW" || poStatus == "PO CREATED");
            //         console.log('values', rowData);
            //         setData(rowData);
            //         setAllData(rowData);
            //         setActionItems(actionData);
            //         const defaultallCount = data.rows.$values.filter(({ rfqStatus, poStatus }: any) => rfqStatus == "COUNTERED" || rfqStatus == "NEW" || poStatus == "PO CREATED").length;
            //         const defaultrfqCount = data.rows.$values.filter(({ rfqStatus }: any) => rfqStatus == "COUNTERED" || rfqStatus == "NEW").length;
            //         const defaultpoCount = data.rows.$values.filter(({ poStatus }: any) =>  poStatus == "PO CREATED").length;
            //         console.log('show all', defaultallCount, defaultrfqCount, defaultpoCount);
            //         setCounter({allCount: defaultallCount, rfqCount: defaultrfqCount, poCount: defaultpoCount});
            //     }

            // })();


        } catch (error) {
            console.log('error', error);
            //let err = error as AxiosError
            //console.log('error', err);
        }
    }, []);





    return (
        <div id="register-page" className="login-page change-password forgot-page">
            <div className="form-bg-wrapper">
                <div className="formbox-wrapper d-flex justify-content-center">
                    <div className="inner-form">
                        <div className="d-flex align-items-baseline justify-content-between flex-wrap">
                            <h3>FORGOT PASSWORD</h3>
                            <div className="img-wrapper text-center">
                                <img src="images/form-logo.svg" alt="" />
                            </div>
                        </div>
                        <p>We will email you instructions to reset your password.</p>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleEmailSubmit}
                        >
                            <Form>
                                <div className="form-group mb-3">
                                    <label htmlFor="EmailToVerify">Email Address</label>
                                    <Field name="EmailToVerify" type="email" />
                                    <ErrorMessage name="Email" className="error-message" component="label" />
                                </div>
                                <div className="button-wrapper d-flex justify-content-end align-items-center">
                                    <button type="submit" disabled={loading} className="login-btn w-100 m-0">
                                        SEND VIA EMAIL
                                    </button>
                                </div>
                                <span className="error-message">{userMessage}</span>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
            <footer className="login-footer">
                <div className="log-wrapper">
                    <img src="images/login-logo.svg" alt="" />
                </div>
                <a href="javascript:">TERMS AND CONDITIONS</a>
                <span className="d-block">© 2018 Builders FirstSource, Inc. All rights reserved.</span>
            </footer>
        </div>
    );
}

export default AccountVerifcation;