import React from "react";
import { Outlet  } from "react-router-dom";
import Header from "./Header/Header";

const TopLayout: React.FC = () => {

  return (
    <>
        <Header />
        <Outlet />
    </>
  );
}

export default TopLayout;