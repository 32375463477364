import React from "react";
import { useState, useEffect } from "react";
import { AxiosError } from 'axios';
import { ApiRequest } from "../api/ApiAxios"
import { NavigateFunction, useNavigate, Link, useSearchParams } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const Login: React.FC = () => {

    let navigate: NavigateFunction = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [userMessage, setUserMessage] = useState<string>("");

    const initialValues: {
        UserEmail: string;
        UserPassword: string;
    } = {
        UserEmail: "",
        UserPassword: ""
    };

    const validationSchema = Yup.object().shape({
        UserEmail: Yup.string().required("This field is required!"),
        UserPassword: Yup.string().required("This field is required!")
    });

    useEffect(() => {

        const hasEmail = searchParams.has("email");
        const hasCode = searchParams.has("code");

        if (hasEmail && hasCode) {
            let requestUrl = 'security/VerifyResponseActivateAccount';

            (async () => {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };
    
                const postData = {
                    "Email": searchParams.get("email"),
                    "Code": searchParams.get("code")
                };
                console.log('login request activate',requestUrl, postData);
               
                const response = await ApiRequest.postRequest(requestUrl, postData, requestOptions);

               setUserMessage(response.userMessage || 'There was an error when activating account.  Contact support.');
            })();
   
        }


    },[]);

    const handleLogin = (formValue: { UserEmail: string; UserPassword: string }) => {
        const { UserEmail, UserPassword } = formValue;
        console.log('handleLogin', formValue)

        try {

            let requestUrl = 'security/login';
            (async () => {
                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };
                const response = await ApiRequest.postRequest(requestUrl, formValue, options);
                console.log('login response', response, response.appUser, response.appUser?.displayName);

                if (response.status === 401) {
                    console.log("login unsuccessful");
                } else if (response.isAuthenticated === false || response.appUser === null) {
                    setUserMessage(response.message || 'There was error in your login request.');
                } else {
                    //save displayname in local storage
                    localStorage.setItem('UserDisplayName', response.appUser.displayName);
                    localStorage.getItem('UserDisplayName');
                    console.log('login - about to navigate to root1', response.appUser.displayName);
                    navigate("/");
                }


            })();


        } catch (error) {
            let err = error as AxiosError
            console.log('error', err);
        }


    };

    return (
        <div className="login-page">
            <div className="form-bg-wrapper">
                <div className="formbox-wrapper d-flex justify-content-center">
                    <div className="inner-form">
                        <div className="img-wrapper text-center mb-2 pb-1">
                            <img src="images/form-logo.svg" alt="" />
                        </div>
                        <h1>VENDOR PORTAL LOGIN</h1>
                        <span className="error-message">{userMessage}</span>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleLogin}
                        >
                            <Form>
                                <div className="form-group mb-3 pb-1">
                                    <label htmlFor="UserEmail">Email Address</label>
                                    <Field name="UserEmail" type="text" />
                                    <ErrorMessage name="UserEmail" className="error-message" component="label" />
                                </div>
                                <div className="form-group mb-2">
                                    <label htmlFor="UserPassword">Password</label>
                                    <Field name="UserPassword" type="password" />
                                    <ErrorMessage name="UserPassword" className="error-message" component="label" />
                                </div>
                                <div className="form-group text-right mb-3">
                                    <Link to="/accountverification" className="text-right">Forgot Password?</Link>
                                </div>
                                <div className="button-wrapper mb-3 pb-1">
                                    <button type="submit" disabled={loading} className="login-btn">
                                        Login
                                    </button>
                                </div>
                            </Form>
                        </Formik>
                        <div className="form-group text-center mt-4 mb-3">
                                    <Link to="/register" className="text-center">Register For A New Account</Link>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="login-footer">
                <div className="log-wrapper">
                    <img src="images/login-logo.svg" alt="" />
                </div>
                <a href="javascript:">TERMS AND CONDITIONS</a>
                <span className="d-block">© 2018 Builders FirstSource, Inc. All rights reserved.</span>
            </footer>
        </div>
    );
}

export default Login;