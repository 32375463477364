import React from "react";
import { useState, useEffect} from "react";
import { NavLink } from "react-router-dom";
// import { Button } from 'semantic-ui-react';

type Props = {
  counter: any;
  onChangeOnlyMe: any;
  showActionValues: any;
};
const Tabs: React.FC<Props> = ({
  counter,
  onChangeOnlyMe,
  showActionValues
}) => {

  const [onlyMeValue, setOnlyMeValue] = useState<boolean>(window.localStorage.getItem('OnlyMe')  !== null ? window.localStorage.getItem('OnlyMe') === "true" : false);
  const [actionValue, setActionValue] = useState<boolean>(window.localStorage.getItem('actionValue')  !== null ? window.localStorage.getItem('actionValue') === "true" : false);

  const localOnChangeOnlyMe = (value:any) => {
    console.log('tabs - local handle change only me', value.target.checked, value);
    setOnlyMeValue(value.target.checked);
    onChangeOnlyMe(value);
  }

  const showActionItems = (value:any) => {
    console.log('tabs - show Action Items', value.target.checked, value);
    setActionValue(value.target.checked);
    showActionValues(value);
  }

  console.log('tabs,onlyme', (window.localStorage.getItem('OnlyMe')  !== null ? window.localStorage.getItem('OnlyMe') === "true" : false));

  useEffect(() => {

  },[])

  return (
    <div className="navigation d-flex align-items-center justify-content-between">
      <ul className="d-flex align-items-center justify-content-between">
        <NavLink
          className={({ isActive }) =>
            [
              "link_nav",
              isActive ? "active" : null,
            ]
              .filter(Boolean)
              .join(" ")
          }
          to="/"
        >
          Show All <span className="count">{counter.allCount}</span>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            [
              "link_nav",
              isActive ? "active" : null,
            ]
              .filter(Boolean)
              .join(" ")
          }
          to="/rfq"
        >
          RFQs Only <span className="count">{counter.rfqCount}</span>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            [
              "link_nav",
              isActive ? "active" : null,
            ]
              .filter(Boolean)
              .join(" ")
          }
          to="/po"
        >
          POs Only <span className="count">{counter.poCount}</span>
        </NavLink>
        <li className="action-link d-flex align-items-center">
          <label htmlFor="action" className="custom-checkbox">
            <input key={Math.random()} id="action" type="checkbox" 
              onChange={(event) => {
                showActionItems(event);
              }}
              checked={actionValue}/>
            <span className="indicator"></span>
            <span className="label-text">Only Show Action Items</span>
          </label>
          </li>
          <li className="action-link d-flex align-items-center">
          <label htmlFor="sent" className="custom-checkbox">
            <input 
              key={Math.random()} id="sent" type="checkbox" 
              onChange={(event) => {
                localOnChangeOnlyMe(event);
              }}
              checked={onlyMeValue}/>
            <span className="indicator"></span>
            <span className="label-text">Only Sent To Me</span>
          </label>
          </li>
      </ul>
      {/* <Button>Export</Button> */}
    </div>
  );
}

export default Tabs;